import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loginMicrosoft } from "../service/user.service";
import Cookies from "js-cookie";



async function getUserInfo(accessToken) {
    console.log("Access Token:", accessToken);
    const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching user info: ${response.statusText}`);
    }

    const userData = await response.json();
    return userData;
}

async function getCompanyInfo(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/organization", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching company info: ${response.statusText}`);
    }

    const companyData = await response.json();
    return companyData;
}

async function getAllEmployees(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/users", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching employees: ${response.statusText}`);
    }

    const employeeData = await response.json();
    return employeeData.value; // List of users
}

async function getUserPhoto(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching user photo: ${response.statusText}`);
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob); // This creates a URL for displaying the image
}

async function getUserDetails(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/me?$select=displayName,mail,jobTitle,companyName,department", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching user details: ${response.statusText}`);
    }

    const userData = await response.json();
    return userData;
}

async function isUserAdmin(accessToken) {
    const response = await fetch("https://graph.microsoft.com/v1.0/me/memberOf", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching user roles: ${response.statusText}`);
    }

    const data = await response.json();

    // Check if the user is a member of a role that indicates admin privileges
    const adminRoles = ["Global Administrator", "User Administrator", "Directory Administrator"];
    const isAdmin = data.value.some(role => adminRoles.includes(role.displayName));

    return isAdmin;
}

const OauthPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Parse the URL to get the authorization code
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        const state = params.get("state");

        if (!code) {
            console.error("No authorization code found");
            navigate("/login"); // Redirect back if no code
            return;
        }

        console.log("Authorization Code:", code);

        // Save code in localStorage (optional)
        localStorage.setItem("auth_code", code);

        // Exchange code for token
        // exchangeCodeForToken(code);
        handleAuthCallback();
    }, [navigate]);

    async function getAllUsersDepartments(accessToken) {
        const response = await fetch("https://graph.microsoft.com/v1.0/users?$select=displayName,department,mail", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching user details: ${response.statusText}`);
        }

        const userData = await response.json();

        // Return an array of users with their display name and department
        return userData.value.map(user => ({
            displayName: user.displayName,
            department: user.department
        }));
    }

    function handleAuthCallback() {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const state = urlParams.get("state");
        const storedState = sessionStorage.getItem("pkce_state");

        if (!code) {
            throw new Error("Authorization code not found in URL.");
        }

        if (state !== storedState) {
            throw new Error("State mismatch. Possible CSRF attack or session error.");
        }

        // Proceed with token exchange
        return exchangeCodeForToken(code);
    }

    function logoutMicrosoft() {
        // Define your app's client ID and post-logout redirect URI
        const clientId = "1d572366-4e37-4688-9615-f640a1ac7bc3";
        const postLogoutRedirectUri = "https://profile.oneplace.hr/login"; // Redirect to login page after logout

        // Construct the Microsoft Entra ID logout URL
        const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}&client_id=${clientId}`;

        // Redirect the user to the logout URL
        window.location.href = logoutUrl;
    }

    // Function to exchange authorization code for access token
    async function exchangeCodeForToken(code) {
        try {
            // Retrieve the code_verifier from sessionStorage (consistent with buildAuthUrl)
            const codeVerifier = sessionStorage.getItem("pkce_code_verifier");
            if (!codeVerifier) {
                throw new Error("Code verifier not found in sessionStorage. PKCE flow cannot proceed.");
            }

            // Define constants (consistent with buildAuthUrl)
            const clientId = "1d572366-4e37-4688-9615-f640a1ac7bc3";
            const redirectUri = "https://profile.oneplace.hr/oauth2/login";
            // const redirectUri = "http://localhost:3000/oauth2/login";
            const tokenEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/token";


            // Send the token exchange request
            const response = await fetch(tokenEndpoint, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    client_id: clientId,
                    grant_type: "authorization_code",
                    code,
                    redirect_uri: redirectUri,
                    code_verifier: codeVerifier,
                }),
            });

            // Check if the response is successful
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Token exchange failed: ${errorData.error_description || response.statusText}`);

            }

            const data = await response.json();
            console.log("Token Response:", data);

            // Clean up sessionStorage after successful token exchange
            sessionStorage.removeItem("pkce_code_verifier");
            sessionStorage.removeItem("pkce_state"); // If state was stored in buildAuthUrl
            getUserInfo(data.access_token)

            // Handle the token response
            if (data.access_token) {
                // Call your loginMicrosoft function to get the metacog_token
                const res = await loginMicrosoft({ username: data.access_token });
                if (res.data.accessToken) {
                    const token = res.data.accessToken;
                    Cookies.set("metacog_token", token, { expires: 7 });
                    navigate({ pathname: "/" });
                } else {
                    console.error("Failed to get metacog_token:", res.data);
                    // logoutMicrosoft();

                    navigate("/login");
                }
            } else {
                console.error("No access token in response:", data);
                // logoutMicrosoft();

                navigate("/login");
            }
        } catch (error) {
            console.error("Error exchanging code for token:", error.message);
            logoutMicrosoft();

            navigate("/login");
        }
    }

    return <div>Redirecting...</div>;
};

export default OauthPage;
