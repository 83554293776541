import { useLanguage } from "../../context/LanguageContext";

const LanguageSelector = () => {
  const { changeLanguage, language } = useLanguage();

  return (
    <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
      <option value="en">English</option>
      <option value="mn">Монгол</option>
      <option value="rs">Руский</option>
    </select>
  );
};

export default LanguageSelector;
