import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaSleigh } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { MdNearMe, MdOutlineFilterAlt } from "react-icons/md";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { format } from "date-fns";
import { mn, ru, enUS } from "date-fns/locale";
import {
  getHistoryMood,
  getMood,
  postMood,
} from "../../../service/home.service";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toast } from "react-toastify";
import Avatar from "../../Avatar";
import { useLanguage } from "../../../context/LanguageContext";

const DesktopHome = ({ hob, hobby, birth }) => {
  const [hover, setHover] = useState(0);
  const [chosen, setChosen] = useState(0);
  const [text, setText] = useState("");
  const [saved, setSaved] = useState(null);
  const [history, setHistory] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);

  const [filter, setFilter] = useState(false);
  const [his, setHis] = useState(null);
  const [weekData, setWeekData] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(0);
  const { t, language } = useLanguage();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    takeMood();
  }, []);

  useEffect(() => {
    getHistoryMood(currentWeek)
      .then((data) => {
        setHis(data.data);
        const transformedData = transformDataByWeek(data.data, currentWeek);
        setWeekData(transformedData);
      })
      .catch((err) => console.log(err));
  }, [currentWeek]);

  const moodMapping = {
    EXCELLENT: 5,
    GOOD: 4,
    NORMAL: 3,
    BAD: 2,
    POOR: 1,
  };

  const moodIcons = {
    1: "/img/home/emoji5.gif",
    2: "/img/home/emoji4.gif",
    3: "/img/home/emoji3.gif",
    4: "/img/home/emoji2.gif",
    5: "/img/home/emoji1.gif",
  };

  const transformDataByWeek = (data, weekOffset) => {
    const daysInWeek = 7;
    const today = new Date();
    const startOfWeek = getStartOfWeek(today, weekOffset);

    const weekData = [];

    const dayNames = {
      mn: ["Даваа", "Мягмар", "Лхагва", "Пүрэв", "Баасан", "Бямба", "Ням"],
      en: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      rs: [
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
        "Воскресенье",
      ],
    };

    for (let i = 0; i < daysInWeek; i++) {
      const day = new Date(startOfWeek);
      day.setDate(day.getDate() + i);
      const dayStr = formatDate(day);

      const dayData = data.filter(
        (entry) => formatDate(new Date(entry.createdDate)) === dayStr
      );
      const moodValues = dayData.map((entry) => moodMapping[entry.mood] || 0);
      const avgMood =
        moodValues.length > 0
          ? moodValues.reduce((a, b) => a + b) / moodValues.length
          : 0;

      const dayName = dayNames[language][i];
      weekData.push({ day: dayName, mood: avgMood });
    }

    return weekData;
  };
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const getStartOfWeek = (date, weekOffset) => {
    const dayOfWeek = date.getDay() || 7;
    const startDate = new Date(date);
    startDate.setDate(date.getDate() - dayOfWeek + 1 + weekOffset * 7);
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  };

  const getWeekDateRange = (currentWeek) => {
    const startDate = new Date();
    startDate.setDate(
      startDate.getDate() - startDate.getDay() + currentWeek * 7
    );

    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    let startMonth, startWeek, endWeek;

    if (language === "mn") {
      startMonth = format(startDate, "MM", { locale: mn });
      startWeek = format(startDate, "d", { locale: mn });
      endWeek = format(endDate, "d", { locale: mn });
      return `${startMonth} дугаар сар, ${startWeek} - ${endWeek} хоног`;
    } else if (language === "rs") {
      startMonth = format(startDate, "MM", { locale: ru });
      startWeek = format(startDate, "d", { locale: ru });
      endWeek = format(endDate, "d", { locale: ru });
      return `${startMonth} месец, ${startWeek} - ${endWeek} дана`;
    } else if (language === "en") {
      startMonth = format(startDate, "MMMM", { locale: enUS });
      startWeek = format(startDate, "d", { locale: enUS });
      endWeek = format(endDate, "d", { locale: enUS });
      return `${startMonth}, ${startWeek} - ${endWeek} days`;
    }
  };

  const takeMood = async () => {
    try {
      const data = await getMood();
      if (data?.data?.mood) {
        setSaved({
          mood: data.data.mood,
          text: data.data.text,
        });
        setCanSubmit(false);
      } else {
        setCanSubmit(true);
      }
    } catch (err) {
      console.log("Error fetching mood:", err);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    const moodMapping = {
      1: "EXCELLENT",
      2: "GOOD",
      3: "NORMAL",
      4: "BAD",
      5: "POOR",
    };

    const moodData = {
      mood: moodMapping[chosen] || "UNKNOWN",
      text: text,
    };

    try {
      await postMood(moodData);

      setSaved({
        mood: moodData.mood,
        text: moodData.text,
      });
      setCanSubmit(false);
      takeMood();
      setChosen(0);
      setText("");
      setSaving(false);
    } catch (err) {
      console.log("Error saving mood:", err);
      setSaving(false);
      toast.error(t("home.err"));
    }
  };

  const handleEdit = () => {
    setText(saved.text);
    setChosen(saved.feels);
    setSaved(null);
  };

  const handleSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleRemove = (item) => {
    setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
  };

  const filteredItems = hob
    ? hob.filter((h) => h.text.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  const filteredHobby = selected.length
    ? hobby.filter((item) =>
        item.matchedHobbies.some((hobbyItem) =>
          selected.some(
            (selectedItem) => selectedItem.text === hobbyItem.hobbyText
          )
        )
      )
    : hobby;

  const CustomYAxisTick = ({ x, y, payload }) => (
    <image
      x={x - 26}
      y={y - 12}
      xlinkHref={moodIcons[payload.value]}
      width="24"
      height="24"
    />
  );

  const CustomXAxisTick = ({ x, y, payload }) => (
    <text
      x={x}
      y={y + 10}
      textAnchor="middle"
      fill="#ABADB5"
      className="text-[11px]"
    >
      {payload.value}
    </text>
  );

  return (
    <div className="relative">
      <div className="flex items-center justify-center">
        <div
          className={`bg-[#fff] px-4 py-4 rounded-xl flex items-center justify-center relative ${
            history === false
              ? chosen === 0
                ? "w-[417px] h-[190px]"
                : "w-[623px] h-[260px]"
              : "w-auto h-auto"
          }`}
        >
          {history ? (
            <>
              <button
                onClick={() => {
                  setHistory(false);
                }}
                className="flex items-center gap-2 text-[#1E293B] text-sm absolute top-3 left-3"
              >
                <FaChevronLeft /> {t("home.back")}
              </button>
              <div className="flex items-center justify-between gap-4">
                {saved !== null && (
                  <div className="py-4">
                    <img
                      className="w-[55px]"
                      src={`/img/home/emoji${(() => {
                        const moodScoreMapping = {
                          EXCELLENT: 1,
                          GOOD: 2,
                          NORMAL: 3,
                          BAD: 4,
                          POOR: 5,
                        };
                        return moodScoreMapping[saved.mood] || "Unknown";
                      })()}.gif`}
                      alt="emoji"
                    />
                    <p className="text-[#ABADB5] text-xs">
                      {new Date().toISOString().slice(0, 10).replace(/-/g, "/")}
                    </p>
                    <p className="text-[#6B7280] text-xs w-full">
                      {saved.text}
                    </p>
                  </div>
                )}
                <div className="w-[750px] h-[350px] rounded-lg p-6">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={weekData}>
                      <XAxis
                        dataKey="day"
                        tick={<CustomXAxisTick />}
                        axisLine={false}
                        tickLine={false}
                      />
                      <YAxis
                        dataKey="mood"
                        ticks={[1, 2, 3, 4, 5]}
                        tick={<CustomYAxisTick />}
                        axisLine={false}
                        tickLine={false}
                        width={50}
                      />
                      <Tooltip
                        content={({ payload }) =>
                          payload && payload.length ? (
                            payload[0].value > 0 ? (
                              <div className="p-2 rounded ">
                                <img
                                  src={moodIcons[payload[0].value]}
                                  alt="Mood"
                                  className="w-6 h-6 inline-block"
                                />
                              </div>
                            ) : null
                          ) : null
                        }
                      />
                      <Line
                        type="monotone"
                        dataKey="mood"
                        stroke="#CECFD3"
                        strokeWidth={2}
                        dot={{
                          r: 5,
                          stroke: "#CECFD3",
                          fill: "white",
                          strokeWidth: 2,
                        }}
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  <div className="flex items-center gap-2 justify-center">
                    <button onClick={() => setCurrentWeek((prev) => prev - 1)}>
                      <FaChevronLeft />
                    </button>
                    <p className="text-center text-gray-600 text-sm">
                      {getWeekDateRange(currentWeek)}
                    </p>
                    <button onClick={() => setCurrentWeek((prev) => prev + 1)}>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {saved === null ? (
                <div className="flex items-center justify-center">
                  <div>
                    {chosen === 0 && (
                      <p className="mb-2 text-center text-sm text-[#1E293B]">
                        {t("home.today")}
                      </p>
                    )}
                    <div className="flex items-center justify-center gap-2">
                      <button
                        onMouseEnter={() => {
                          setHover(1);
                        }}
                        onMouseLeave={() => {
                          setHover(0);
                        }}
                        onClick={() => {
                          setChosen(1);
                        }}
                      >
                        <img
                          className={`${
                            hover === 1 ? "w-[40px]" : "w-[32px]"
                          } ${chosen === 1 ? "w-[55px]" : "w-[32px]"}`}
                          src="/img/home/emoji1.gif"
                          alt="emoji"
                        />
                      </button>
                      <button
                        onMouseEnter={() => {
                          setHover(2);
                        }}
                        onMouseLeave={() => {
                          setHover(0);
                        }}
                        onClick={() => {
                          setChosen(2);
                        }}
                      >
                        <img
                          className={`${
                            hover === 2 ? "w-[40px]" : "w-[32px]"
                          } ${chosen === 2 ? "w-[55px]" : "w-[32px]"}`}
                          src="/img/home/emoji2.gif"
                          alt="emoji"
                        />
                      </button>
                      <button
                        onMouseEnter={() => {
                          setHover(3);
                        }}
                        onMouseLeave={() => {
                          setHover(0);
                        }}
                        onClick={() => {
                          setChosen(3);
                        }}
                      >
                        <img
                          className={`${
                            hover === 3 ? "w-[40px]" : "w-[32px]"
                          } ${chosen === 3 ? "w-[55px]" : "w-[32px]"}`}
                          src="/img/home/emoji3.gif"
                          alt="emoji"
                        />
                      </button>
                      <button
                        onMouseEnter={() => {
                          setHover(4);
                        }}
                        onMouseLeave={() => {
                          setHover(0);
                        }}
                        onClick={() => {
                          setChosen(4);
                        }}
                      >
                        <img
                          className={`${
                            hover === 4 ? "w-[40px]" : "w-[32px]"
                          } ${chosen === 4 ? "w-[55px]" : "w-[32px]"}`}
                          src="/img/home/emoji4.gif"
                          alt="emoji"
                        />
                      </button>
                      <button
                        onMouseEnter={() => {
                          setHover(5);
                        }}
                        onMouseLeave={() => {
                          setHover(0);
                        }}
                        onClick={() => {
                          setChosen(5);
                        }}
                      >
                        <img
                          className={`${
                            hover === 5 ? "w-[40px]" : "w-[32px]"
                          } ${chosen === 5 ? "w-[55px]" : "w-[32px]"}`}
                          src="/img/home/emoji5.gif"
                          alt="emoji"
                        />
                      </button>
                    </div>
                    {chosen !== 0 && (
                      <div>
                        <p className="text-center text-[##1E293B] text-sm">
                          {t("home.why")}
                        </p>
                        <textarea
                          className="border border-[#B0B0B0] bg-[#f8f8f8] text-xs w-full h-[100px] rounded-xl p-2.5"
                          placeholder={t("home.place")}
                          value={text}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                        />
                        {text !== "" && (
                          <div className="flex justify-end mt-4">
                            <button
                              disabled={!canSubmit}
                              onClick={handleSave}
                              className="text-[#fff] text-sm bg-[#040405] px-3 py-1 rounded-lg "
                            >
                              {saving ? (
                                <svg
                                  className="w-4 h-4 animate-spin text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                t("home.save")
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-4">
                  <img
                    className="w-[40px]"
                    src={`/img/home/emoji${(() => {
                      const moodScoreMapping = {
                        EXCELLENT: 1,
                        GOOD: 2,
                        NORMAL: 3,
                        BAD: 4,
                        POOR: 5,
                      };
                      return moodScoreMapping[saved.mood] || "Unknown";
                    })()}.gif`}
                    alt="emoji"
                  />
                  <div>
                    <p className="text-[#ABADB5] text-xs">
                      {" "}
                      {new Date().toISOString().slice(0, 10).replace(/-/g, "/")}
                    </p>
                    <p className="text-[#6B7280] text-xs">{saved.text}</p>
                  </div>
                </div>
              )}
            </>
          )}
          {!history && chosen === 0 && (
            <button
              onClick={() => {
                setHistory(true);
              }}
              className="underline text-sm text-[#324d72] absolute bottom-3 right-3"
            >
              {t("home.prev")}
            </button>
          )}
        </div>
      </div>

      <hr className="text-[#CEDAE9] my-8" />
      <div>
        {birth.filter((person) => person.daysUntilNextBirthday === 365).length >
          0 && (
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-[#1E293B] text-sm font-semibold">
                  {t("home.birthday")}
                </p>
                <p className="text-xs text-[#1E293B]">{t("home.happy")}</p>
              </div>
              <div>
                <p className="text-[#1E293B] text-xs">
                  {new Date().toISOString().split("T")[0]}
                </p>
              </div>
            </div>
            <div></div>
          </div>
        )}

        <div className="overflow-x-auto whitespace-nowrap flex items-center gap-4 mb-4 w-[105%]">
          {birth
            .filter((person) => person.daysUntilNextBirthday === 365)
            .map((person) => (
              <div
                key={person.id}
                style={{ backgroundImage: 'url("/img/home/bday.svg")' }}
                className="h-full bg-cover bg-center rounded-xl w-[320px] min-w-[320px] flex-shrink-0"
              >
                <div className="w-full bg-[#C754C0] bg-opacity-20 rounded-t-[20px] h-[60px] relative">
                  <div className="bg-white h-[50px] w-[50px] flex items-center justify-center p-1 rounded-lg absolute left-6 -bottom-3">
                    {person.profileUrl === null ? (
                      <Avatar
                        lastName={person.lastName}
                        firstName={person.firstName}
                      />
                    ) : (
                      <img
                        className="w-full h-full"
                        src={person.profileUrl}
                        alt="user"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full bg-[#C850C0] bg-opacity-90 rounded-b-[20px] h-[117px]">
                  <div className="flex items-center justify-between w-full pt-3 px-6">
                    <div>
                      <p className="text-white font-semibold">
                        {person.firstName} {person.lastName}
                      </p>
                      <p className="text-white text-xs">{person.position}</p>
                    </div>
                    <div>
                      <p className="text-white text-[20px] mb-0">
                        {Math.floor(
                          (new Date().getFullYear() -
                            new Date(person.birthDay).getFullYear()) *
                            1000
                        ) / 1000}
                      </p>
                      <p className="text-white text-xs">{t("home.age")}</p>
                    </div>
                  </div>
                  {/* <div className="flex flex-col items-center justify-center w-full pt-3">
                    <div className="bg-white w-[30px] h-[30px] rounded-lg flex items-center justify-center">
                      <img src="/img/home/gift.svg" alt="gift" />
                    </div>
                    <p className="text-white text-[8px] text-center">
                      Бэлэг илгээх
                    </p>
                  </div> */}
                </div>
              </div>
            ))}
        </div>

        <div>
          <div className="mb-4">
            <p className="text-sm text-[#1E293B] font-semibold">
              {t("home.soon")}
            </p>
            <p className="text-xs text-[#1E293B]">{t("home.soonHappy")}</p>
          </div>

          {birth.filter((item) => {
            const birthDate = new Date(item.birthDay);
            const today = new Date();

            return (
              birthDate.getMonth() === today.getMonth() &&
              birthDate.getDate() > today.getDate()
            );
          }).length > 0 ? (
            birth
              .filter((item) => {
                const birthDate = new Date(item.birthDay);
                const today = new Date();

                return (
                  birthDate.getMonth() === today.getMonth() &&
                  birthDate.getDate() > today.getDate()
                );
              })
              .map((item, index) => {
                const birthDate = new Date(item.birthDay);
                const day = birthDate.getDate();
                const month = birthDate.getMonth() + 1;

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between w-full py-2 px-5 rounded-xl bg-[#fff] mb-4"
                  >
                    <div className="flex items-center gap-4">
                      <div className="w-[40px] h-[40px] p-[3px] rounded-lg bg-[#fff] flex items-center justify-center">
                        {item.profileUrl ? (
                          <img
                            className="object-cover w-full h-full rounded-full"
                            src={item.profileUrl}
                            alt="user"
                          />
                        ) : (
                          <Avatar
                            lastName={item.lastName}
                            firstName={item.firstName}
                          />
                        )}
                      </div>

                      <div>
                        <p className="text-[#324D72] text-xs font-semibold">
                          {item.lastName} {item.firstName}
                        </p>
                        <p className="text-[#324D72] text-[10px]">
                          {item.position}
                        </p>

                        <p className="text-[#324D72] text-[10px]">
                          <span className="text-[12px]">
                            {month} {t("home.month")} {day}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="text-center">
                        <p className="text-[#324D72] text-[15px]">
                          {item.daysUntilNextBirthday}
                        </p>
                        <p className="text-[#324D72] text-[10px] opacity-80">
                          {t("home.dayleft")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="w-full text-center bg-[#fff] py-4 rounded-xl">
              <div className="flex justify-center">
                <img src="/img/profile/birthday.svg" alt="birth" />
              </div>
              <p className="text-center text-[#1E293B] text-sm">
                {t("home.noThisMonth")}
              </p>
            </div>
          )}
        </div>
      </div>
      <hr className="text-[#CEDAE9] my-8" />
      <div>
        <div className="flex items-center justify-between mb-6">
          <p className="text-[#1E293B] text-sm font-semibold">
            {t("home.same")}
          </p>
          <div className="w-full flex justify-end mb-4">
            <button
              onClick={() => {
                setFilter(true);
              }}
              className="flex items-center gap-2 text-[#324D72] text-xs border border-[#CEDAE9] rounded-lg px-2 py-1 bg-[#fff]"
            >
              {t("home.filter")}
              <span className="text-base">
                <MdOutlineFilterAlt />
              </span>
            </button>
          </div>
        </div>
        {filteredHobby.length > 0 ? (
          filteredHobby.map((item, index) => (
            <div
              key={index}
              className="shadow-custom bg-[#fff] py-3 px-5 rounded-xl mb-4"
            >
              <div className="flex items-center gap-4 mb-3">
                <div className="flex rounded-full border w-[40px] h-[40px] items-center justify-center">
                  {item.profileUrl ? (
                    <img
                      className="object-cover w-full h-full rounded-full"
                      src={item.profileUrl}
                      alt="user"
                    />
                  ) : (
                    <Avatar
                      lastName={item.lastName}
                      firstName={item.firstName}
                    />
                  )}
                </div>
                <div>
                  <p className="text-[#1E293B] text-sm">
                    {item.firstName} {item.lastName}
                  </p>
                  <p className="text-[#64748B] text-sm">
                    {item.departmentName}, {item.position}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-[#64748B] text-xs mb-1">{t("home.comp")}</p>
                <div className="flex flex-wrap items-center gap-2 mb-3">
                  {item.matchedHobbies.map((hobbyItem) => (
                    <div
                      key={hobbyItem.hobbyId}
                      className="px-2 py-1 bg-[#7EEFB2] bg-opacity-20 rounded-lg"
                    >
                      <p className="text-[#3D608C] text-xs">
                        {hobbyItem.hobbyText}
                      </p>
                    </div>
                  ))}
                </div>
                {item.inMatchedHobbies.length > 0 && (
                  <div>
                    <p className="text-[#64748B] text-xs mb-1">
                      {t("home.other")}
                    </p>
                    <div className="flex flex-wrap items-center gap-2">
                      {item.inMatchedHobbies.map((hobbyItem) => (
                        <div
                          key={hobbyItem.hobbyId}
                          className="px-2 py-1 bg-[#F4F6FB] bg-opacity-20 rounded-lg"
                        >
                          <p className="text-[#3D608C] text-xs">
                            {hobbyItem.hobbyText}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="mt-6 w-full py-6 rounded-xl mb-10 bg-white">
            <div className="flex justify-center">
              <img src="/img/profile/empty.svg" alt="empty" />
            </div>
            <div className="text-center">
              <p className="text-[#1E293B] text-sm font-semibold">
                {t("home.noSame")}
              </p>
            </div>
          </div>
        )}
      </div>
      {filter && (
        <div
          onClick={() => setFilter(false)}
          className="fixed top-0 left-0 w-full h-screen bg-[#000] bg-opacity-40 flex items-center justify-center z-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-[#fff] rounded-xl px-6 pb-6 pt-10 w-[300px] relative "
          >
            <button
              onClick={() => {
                setFilter(false);
              }}
              className="absolute top-4 right-4 text-lg text-[#1E293B]"
            >
              <IoMdClose />
            </button>
            <div className="relative mb-2">
              <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
              <input
                className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                placeholder={t("home.search")}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {selected.length > 0 && (
              <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll my-2">
                {selected.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleRemove(item)}
                    className="bg-[#3D608C] px-2 py-1 rounded-lg flex items-center gap-2"
                  >
                    <p className="text-xs text-white">{item.text}</p>
                    <IoMdClose className="text-white" />
                  </button>
                ))}
              </div>
            )}
            <p className="my-4 text-[#1E293B] text-xs">{t("home.choose")}</p>
            <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll">
              {filteredItems?.map((item) => (
                <button
                  key={item.id}
                  onClick={() => handleSelect(item)}
                  className={`px-2 py-1 rounded-lg text-xs ${
                    selected.some((selectedItem) => selectedItem.id === item.id)
                      ? "bg-[#3D608C] text-[#fff]"
                      : "bg-[#F4F6FB] text-[#3D608C]"
                  }`}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* <div className="mt-6 w-full flex items-start justify-between gap-10">
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <TfiMenuAlt />
            Гүйцэтгэх шаардлагатай зүйлс
          </p>
          <div className="mb-6 shadow-xl bg-white px-4 py-2 flex items-center gap-4 rounded-xl">
            <div className="w-[40%]">
              <div className="flex items-start gap-2 ">
                <img src="/img/home/star.svg" alt="star" />
                <div>
                  <p className="font-semibold text-sm text-[#222]">Task name</p>
                  <p className="text-xs text[#222] opacity-80 mb-8">2 tasks</p>
                  <div className="ms-5 relative flex items-center justify-center bg-[#EEEFF1] border-[#324D72] border rounded-xl w-[25px] h-[15px]">
                    <img
                      className="absolute bottom-2"
                      src="./img/location.svg"
                      alt="location"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[70%]">
              <div className="flex justify-end">
                <p className="text-[#FF9D72] bg-[#FFF3ED] w-auto font-semibold mb-6 text-xs px-1.5 py-0.5 me-3 rounded-md text-end">
                  4 өдөр үлдсэн
                </p>
              </div>
              <p className="text-[#636466] text-xs">Эцсийн хугацаа:</p>
              <p className="text-[#222] text-xs font-semibold mb-3">
                2024/11/02
              </p>
              <button className="text-xs text-[#636466] flex items-center gap-2">
                Үргэлжлүүлэх <FaChevronRight />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-4 py-2 px-5 bg-white rounded-xl shadow-xl mb-6">
            <img src="/img/home/star.svg" alt="star" />
            <div>
              <p className="text-[#636466] font-semibold text-sm mb-3">
                Task name- 2 даалгавар дутуу
              </p>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Ажлын хувцас хүлээн авах
                </p>
              </div>
              <div className="flex items-center gap-3 mb-1">
                <input
                  className="rounded-full bg-[#EBEBEB] border-[#BCBCBC] w-[12px] h-[12px]"
                  type="checkbox"
                />
                <p className="text-xs text-[#575763]">
                  Хөдөлмөрийн гэрээтэй танилцах
                </p>
              </div>
              <button className="mt-2 text-[#636466] text-xs flex items-center gap-2">
                Дэлгэрэнгүй
                <FaChevronRight />
              </button>
            </div>
          </div>
          <div>
            <SurvTask data={surv} />
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[#293951] font-semibold mb-10 flex items-center gap-2">
            <FaRegBell />
            Сүүлийн үеийн шинэчлэлүүд
          </p>

          <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
            <div className="flex items-center justify-between">
              <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

              <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                <p className="text-[#FF9D72] font-semibold text-sm">new</p>
              </div>
            </div>
            <div>
              <p className="text-xs text-[#222] py-2">
                Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
              </p>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <div className="p-1 border border-[#000] bg-white rounded-full">
                <LuUser2 className="text-xl text-[#000]" />
              </div>
              <div>
                <p className="text-[#1E293B] text-xs">Овог Нэр</p>
                <p className="text-[#64748B] text-xs">Хэлтэс албан тушаал</p>
              </div>
            </div>
          </div>
          <div className="w-full p-4 bg-white shadow-custom rounded-xl mb-4">
            <div className="flex items-center justify-between">
              <p className="text-[#64748B] text-xs py-0.5">2024/12/10</p>

              <div className="px-1 py-0.5 rounded-md bg-[#FFF3ED] ">
                <p className="text-[#FF9D72] font-semibold text-sm">new</p>
              </div>
            </div>
            <div>
              <p className="text-xs text-[#222] py-2">
                Амар та цаашид хэрэгжүүлж эхлэх зүйл -г хийж эхэлвэл гарах үр
                дүн -д хүрч чадна. Би таныг чадна гэдэгт итгэлтэй байна 🤗
              </p>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <img src="/img/incognito.svg" alt="incognito" />

              <div>
                <p className="text-[#1E293B] text-xs">Нэрээ нууцалсан</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DesktopHome;
