import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../style/animation.css";
import { FaSpinner } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { createFeedback } from "../../../service/feedback.service";
import { toast } from "react-toastify";
import { useLanguage } from "../../../context/LanguageContext";

const DesktopFeedbackEmp = ({ EmpData }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [btn, setBtn] = useState(false);
  const modalRef = useRef();
  const { t, language } = useLanguage();
  const [focus, setFocus] = useState(1);

  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  //modal
  const [modal, setModal] = useState(1);
  const [popUp, setPopUp] = useState(false);

  //start
  const [startWrite, setStartWrite] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //continue
  const [continueWrite, setContinueWrite] = useState("");
  const [shouldAnimate2, setShouldAnimate2] = useState(false);

  //stop
  const [stopWrite, setStopWrite] = useState("");
  const [stopEvent, setStopEvent] = useState("");
  const [shouldAnimate3, setShouldAnimate3] = useState(false);

  //popup2
  const [popUp2, setPopUp2] = useState(true);
  const popUpRef2 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popUp2 &&
        popUpRef2.current &&
        !popUpRef2.current.contains(event.target)
      ) {
        setPopUp2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp2]);

  useEffect(() => {
    let timer;
    if (startWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 1000);
    } else {
      setShouldAnimate(false);
    }
    return () => clearTimeout(timer);
  }, [startWrite]);

  useEffect(() => {
    let timer;
    if (continueWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate2(true);
      }, 1000);
    } else {
      setShouldAnimate2(false);
    }
    return () => clearTimeout(timer);
  }, [continueWrite]);

  useEffect(() => {
    let timer;
    if (stopWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate3(true);
      }, 1000);
    } else {
      setShouldAnimate3(false);
    }
    return () => clearTimeout(timer);
  }, [stopWrite]);

  useEffect(() => {
    if (EmpData !== undefined || EmpData !== null) {
      setData(EmpData);
    }
  }, [EmpData]);

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setPopUp(false);
    }
  };

  if (data === null) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Loading...</p>
      </div>
    );
  }

  if (data === undefined) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Employee not found</p>
      </div>
    );
  }

  const sendBackend = async () => {
    setLoading(true);
    try {
      let startMsg;
      let continueMsg;
      let stopMsg;

      if (startWrite !== "") {
        startMsg = startWrite;
      }

      if (continueWrite !== "") {
        continueMsg = continueWrite;
      }

      if (stopWrite !== "") {
        stopMsg = stopWrite;
      }

      const types = [];

      if (startMsg !== undefined) {
        types.push({
          type: "START",
          text: startMsg,
        });
      }

      if (continueMsg !== undefined) {
        types.push({
          type: "CONTINUE",
          text: continueMsg,
        });
      }

      if (stopMsg !== undefined) {
        types.push({
          type: "STOP",
          text: stopMsg,
          feedbackCase: stopEvent,
        });
      }

      const backendMsg = {
        receivingEmployeeId: EmpData.id,

        hide: isHidden,
        types,
      };

      await createFeedback(backendMsg)
        .then(() => {
          toast.success("Success");
          setLoading(false);
          navigate("/feed-forward");
        })
        .catch((e) => {
          setLoading(false);
          toast.error(t("feedback.emp.err"));
        });

      setLoading(false);
      // console.log("Success:", backendMsg);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="m-auto pt-10 min-h-screen ">
          <div className="flex items-start justify-between">
            {/* <div className="flex flex-col items-center relative w-[200px]">
              <img src="/img/pfpOnPf.svg" alt="aa" />
              <p className="text-center text-[20px] font-semibold">
                {data.firstName} {data.lastName}
              </p>
              <p className="text-[#171717] text-center">
                {data.departmentName}, <br /> {data.position}
              </p>
            </div> */}
            <div className="flex flex-col items-center gap-10 mb-10">
              <div className="flex justify-start ">
                <div
                  onClick={() => {
                    setFocus(1);
                    setModal(1);
                  }}
                  className={`border rounded-full flex flex-col items-center justify-center pb-3 ${
                    shouldAnimate ? "fill-animation-bottom-left" : ""
                  } ${
                    modal === 1
                      ? "border-[#51A8FF] w-[240px] h-[240px] shadow-xl"
                      : "border-[#C6C6C6] w-[200px] h-[200px]"
                  }`}
                  style={{
                    background: shouldAnimate ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 1 ? "w-[69px] h-[69px]" : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon1Apu.png"
                        : "/img/feedback/icons/bIcon1.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={`font-semibold mt-4 ${
                      shouldAnimate ? "text-white-animation" : "text-[#636466]"
                    } ${modal === 1 ? "text-[28px]" : "text-[20px]"}`}
                  >
                    START
                  </p>
                  <p
                    className={` text-center  ${
                      shouldAnimate ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 1
                        ? "text-[16px] w-[210px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    {language === "mn" ? (
                      <>
                        Цаашид юуг{" "}
                        <span className="font-semibold">
                          хэрэгжүүлж эхэлбэл
                        </span>{" "}
                        зохистой тухай зөвлөнө үү.
                      </>
                    ) : language === "rs" ? (
                      <>
                        На что следует{" "}
                        <span className="font-semibold">
                          начать обращать внимание
                        </span>{" "}
                        коллеге?
                      </>
                    ) : (
                      <>
                        What should be{" "}
                        <span className="font-semibold">
                          started implementing
                        </span>{" "}
                        going forward?
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div
                  onClick={() => {
                    setFocus(1);
                    setModal(3);
                  }}
                  className={`border border-[#C6C6C6] rounded-full w-[200px] h-[200px] flex flex-col items-center justify-center pb-3 ${
                    shouldAnimate3 ? "fill-animation-bottom-top" : ""
                  } ${
                    modal === 3
                      ? "border-[#51A8FF] w-[270px] h-[270px] shadow-xl"
                      : "border-[#C6C6C6] w-[200px] h-[200px]"
                  }`}
                  style={{
                    background: shouldAnimate3 ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 3 ? "w-[69px] h-[69px]" : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon3Apu.png"
                        : "/img/feedback/icons/bIcon3.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={` font-semibold mt-2 ${
                      shouldAnimate3 ? "text-white-animation" : "text-[#636466]"
                    } ${modal === 3 ? "text-[28px]" : "text-[20px]"}`}
                  >
                    STOP{" "}
                  </p>
                  <p
                    className={`text-center ${
                      shouldAnimate3 ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 3
                        ? "text-[14px] w-[200px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    {language === "mn" ? (
                      <>
                        Цаашид юуг{" "}
                        <span className="font-semibold">хийхээ зогсоовол</span>{" "}
                        зохистой тухай зөвлөнө үү.
                      </>
                    ) : language === "rs" ? (
                      <>
                        Что нужно{" "}
                        <span className="font-semibold">прекратить</span> в
                        коллеге?
                      </>
                    ) : (
                      <>
                        What should be{" "}
                        <span className="font-semibold">stopped doing</span>{" "}
                        going forward?
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div
                  onClick={() => {
                    setFocus(1);
                    setModal(2);
                  }}
                  className={`border border-[#C6C6C6] rounded-full w-[200px] h-[200px] flex flex-col items-center justify-center  ${
                    shouldAnimate2 ? "fill-animation-bottom-top-left" : ""
                  } ${
                    modal === 2
                      ? "border-[#51A8FF] w-[240px] h-[240px] shadow-xl"
                      : "border-[#C6C6C6] w-[190px] h-[190px]"
                  }`}
                  style={{
                    background: shouldAnimate2 ? "" : "white",
                  }}
                >
                  <img
                    className={`${
                      modal === 2 ? "w-[69px] h-[69px]" : "w-[60px] h-[60px]"
                    }`}
                    src={
                      EmpData.companyId === 197
                        ? "/img/feedback/icons/bIcon2Apu.png"
                        : "/img/feedback/icons/bIcon2.svg"
                    }
                    alt="icon"
                  />
                  <p
                    className={`font-semibold mt-2 ${
                      shouldAnimate2 ? "text-white-animation" : "text-[#636466]"
                    } ${modal === 2 ? "text-[28px]" : "text-[20px]"}`}
                  >
                    Continue
                  </p>
                  <p
                    className={`text-center ${
                      shouldAnimate2 ? "text-white-animation" : "text-[#A1A1A1]"
                    } ${
                      modal === 2
                        ? "text-[14px] w-[210px]"
                        : "text-[12px] w-[160px]"
                    }`}
                  >
                    {language === "mn" ? (
                      <>
                        Цаашид юуг{" "}
                        <span className="font-semibold">
                          үргэлжлүүлэн хэрэгжүүлвэл
                        </span>{" "}
                        зохистой тухай зөвлөнө үү.
                      </>
                    ) : language === "rs" ? (
                      <>
                        Что следует{" "}
                        <span className="font-semibold">продолжать делать</span>{" "}
                        коллеге?
                      </>
                    ) : (
                      <>
                        What should be{" "}
                        <span className="font-semibold">
                          continued implementing
                        </span>{" "}
                        going forward?
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2 mb-24">
                <div className="w-[50px] h-[50px] rounded-full border border-[#ccc]">
                  <img
                    className="w-full h-full object-cover rounded-full"
                    src={data.profileUrl}
                    alt="aaa"
                  />
                </div>
                <div>
                  <p className="text-[#1E293B]">
                    {EmpData.lastName} {EmpData.firstName}
                  </p>
                  <p className="text-sm text-[#666874]">
                    {EmpData.position}, {EmpData.departmentName}
                  </p>
                </div>
              </div>
              <div
                className={`rounded-xl bg-[#fff]  py-4 border border-[#efefef] max-w-[400px] shadow-md px-4  `}
              >
                <div className="px-2">
                  {modal === 1 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon1Apu.png"
                              : "/img/feedback/icons/bIcon1.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">Start</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        {language === "mn" ? (
                          <>
                            Таны өгсөн{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}
                            тухайн ажилтны ажлын бүтээмж, сэтгэлзүйд шууд
                            нөлөөлөх боломжтой тул та эелдгээр зохистой,
                            хэрэгцээтэй зөвлөмжийг өгнө үү.
                          </>
                        ) : language === "en" ? (
                          <>
                            Your{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            can directly influence the productivity and mental
                            state of the employee, so please provide polite and
                            appropriate suggestions.
                          </>
                        ) : (
                          <>
                            Ваш{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            может напрямую повлиять на производительность и
                            психоэмоциональное состояние сотрудника, поэтому,
                            пожалуйста, предоставляйте его в вежливой и уместной
                            форме.
                          </>
                        )}
                      </p>
                      <div className="mt-4">
                        <div className="flex items-start gap-2 mb-3">
                          <div
                            className={`h-4 w-4 rounded-full border-2 mt-2 ${
                              focus === 1
                                ? "border-[#0752DF]"
                                : "border-gray-400"
                            }`}
                          />
                          <div>
                            <div
                              className={`w-[300px] border px-2 py-2 rounded-xl ${
                                focus === 1
                                  ? EmpData.companyId === 197
                                    ? "border-[#41E6FF]"
                                    : "border-[#0752DF]"
                                  : "border-gray-400"
                              }`}
                            >
                              <p
                                className={`text-xs ${
                                  focus === 1
                                    ? EmpData.companyId === 197
                                      ? "text-[#191442]"
                                      : "text-[#0752DF]"
                                    : "text-gray-500"
                                }`}
                              >
                                {EmpData.companyId === 31
                                  ? t("feedback.feed")
                                  : t("feedback.feedback")}{" "}
                              </p>

                              <textarea
                                id="feedback"
                                name="feedback"
                                onFocus={() => setFocus(1)}
                                onChange={(e) => setStartWrite(e.target.value)}
                                rows="3"
                                className="mt-1 block w-full text-[12px] h-[70px]"
                                placeholder={t("feedback.emp.write")}
                                value={startWrite}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        {startWrite !== "" ? (
                          <button
                            onClick={() => {
                              setModal(3);
                              setFocus(1);
                            }}
                            className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                          >
                            {t("feedback.emp.co")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>{" "}
                    </div>
                  ) : modal === 2 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon2Apu.png"
                              : "/img/feedback/icons/bIcon2.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">Continue</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        {language === "mn" ? (
                          <>
                            Тухайн ажилтны одоо хийж байгаа бүтэмжтэй, таатай
                            хандлагыг урамшуулж цаашдаа энэхүү хандлагаа тогтоон
                            барихад туслах{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            бичнэ үү.
                          </>
                        ) : language === "rs" ? (
                          <>
                            Похвалите текущую продуктивность и позитивный
                            настрой этого человека и помогите ему сохранить этот
                            настрой, написав{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            .
                          </>
                        ) : (
                          <>
                            Encourage the employee's current productivity and
                            positive attitude, and help them maintain this
                            attitude by writing{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            .
                          </>
                        )}
                      </p>

                      <div className="mt-4">
                        <div className="flex items-start gap-2 mb-3">
                          <div
                            className={`h-4 w-4 rounded-full border-2 mt-2 ${
                              focus === 1
                                ? "border-[#0752DF]"
                                : "border-gray-400"
                            }`}
                          />
                          <div>
                            <div
                              className={`w-[300px] border px-2 py-2 rounded-xl ${
                                focus === 1
                                  ? EmpData.companyId === 197
                                    ? "border-[#41E6FF]"
                                    : "border-[#0752DF]"
                                  : "border-gray-400"
                              }`}
                            >
                              <p
                                className={`text-xs ${
                                  focus === 1
                                    ? EmpData.companyId === 197
                                      ? "text-[#191442]"
                                      : "text-[#0752DF]"
                                    : "text-gray-500"
                                }`}
                              >
                                {EmpData.companyId === 31
                                  ? t("feedback.feed")
                                  : t("feedback.feedback")}{" "}
                              </p>

                              <textarea
                                id="feedback"
                                name="feedback"
                                onFocus={() => setFocus(1)}
                                onChange={(e) =>
                                  setContinueWrite(e.target.value)
                                }
                                rows="3"
                                className="mt-1 block w-full text-[12px] h-[70px]"
                                placeholder={t("feedback.emp.write")}
                                value={continueWrite}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        {continueWrite !== "" ? (
                          <button
                            onClick={() => {
                              setModal(2);

                              setPopUp(true);
                              setFocus(1);
                            }}
                            className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                          >
                            {t("feedback.emp.co")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : modal === 3 ? (
                    <div>
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[48px] w-[48px]"
                          src={
                            EmpData.companyId === 197
                              ? "/img/feedback/icons/bIcon3Apu.png"
                              : "/img/feedback/icons/bIcon3.svg"
                          }
                          alt="icon"
                        />
                        <p className="font-semibold">STOP</p>
                      </div>
                      <p className="text-xs opacity-70 w-[325px] mt-2">
                        {language === "mn" ? (
                          <>
                            Тухайн ажилтны одоо гаргаж буй таагүй, цухалдуулам
                            хандлага, үйлдлийг зогсооход чиглэсэн{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            өгөх ба ингэснээрээ ямар үр дүнд хүрж болохыг
                            эелдгээр илэрхийлнэ үү.
                          </>
                        ) : language === "rs" ? (
                          <>
                            Укажите на текущее негативное и провокационное
                            поведение и поступки человека, предложите{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            , который поможет в их исправлении, а также опишите
                            возможные результаты таких изменений.
                          </>
                        ) : (
                          <>
                            Address the employee's current negative and
                            provocative behaviors and actions, and provide{" "}
                            {EmpData.companyId === 31
                              ? t("feedback.feed")
                              : t("feedback.feedback")}{" "}
                            to help with this, along with the potential outcomes
                            it could bring.
                          </>
                        )}
                      </p>

                      <div className="mt-4">
                        <div className="flex items-start gap-2 mb-3">
                          <div
                            className={`h-4 w-4 rounded-full border-2 mt-2 ${
                              focus === 2
                                ? "border-[#0752DF]"
                                : "border-gray-400"
                            }`}
                          />
                          <div>
                            <div
                              className={`w-[300px] border px-2 py-2 rounded-xl ${
                                focus === 2
                                  ? EmpData.companyId === 197
                                    ? "border-[#41E6FF]"
                                    : "border-[#0752DF]"
                                  : "border-gray-400"
                              }`}
                            >
                              <p
                                className={`text-xs ${
                                  focus === 2
                                    ? EmpData.companyId === 197
                                      ? "text-[#191442]"
                                      : "text-[#0752DF]"
                                    : "text-gray-500"
                                }`}
                              >
                                {t("feedback.emp.sbi")}
                              </p>

                              <textarea
                                id="feedback"
                                name="feedback"
                                onFocus={() => setFocus(2)}
                                onChange={(e) => setStopEvent(e.target.value)}
                                rows="3"
                                className="mt-1 block w-full text-[12px] h-[70px]"
                                placeholder={t("feedback.emp.sbiplc")}
                                value={stopEvent}
                              ></textarea>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="flex items-start gap-2 mb-3">
                          <div
                            className={`h-4 w-4 rounded-full border-2 mt-2 ${
                              focus === 3
                                ? "border-[#0752DF]"
                                : "border-gray-400"
                            }`}
                          />
                          <div>
                            {" "}
                            <div
                              className={`w-[300px] border px-2 py-2 rounded-xl ${
                                focus === 3
                                  ? EmpData.companyId === 197
                                    ? "border-[#41E6FF]"
                                    : "border-[#0752DF]"
                                  : "border-gray-400"
                              }`}
                            >
                              <p
                                className={`text-xs ${
                                  focus === 3
                                    ? EmpData.companyId === 197
                                      ? "text-[#191442]"
                                      : "text-[#0752DF]"
                                    : "text-gray-500"
                                }`}
                              >
                                {t("feedback.emp.acc")}{" "}
                              </p>

                              <textarea
                                id="feedback"
                                name="feedback"
                                onFocus={() => setFocus(3)}
                                onChange={(e) => setStopWrite(e.target.value)}
                                rows="3"
                                className="mt-1 block w-full text-[12px] h-[70px]"
                                placeholder={t("feedback.emp.accplc")}
                                value={stopWrite}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        {stopWrite !== "" ? (
                          <button
                            onClick={() => {
                              setModal(2);
                              setFocus(1);
                            }}
                            className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                          >
                            {t("feedback.emp.co")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mx-6 mb-6 mt-4">
            {btn && (
              <button
                onClick={() => {
                  setPopUp(true);
                }}
                className="px-6  py-2  w-[150px]  text-sm text-white font-semibold bg-[#222] rounded-lg"
              >
                {t("feedback.emp.sent")}
              </button>
            )}
          </div>
        </div>{" "}
        {popUp && (
          <div
            onClick={handleModalClickOutside}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          >
            <div
              ref={modalRef}
              className="bg-white rounded-xl py-6 px-12 relative"
            >
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className="absolute top-2 right-3"
              >
                <IoCloseOutline className="text-2xl text-[#444]" />
              </button>
              <p className="text-center text-[#444] text-sm font-semibold mb-1">
                {language === "mn" ? (
                  <>
                    Та{" "}
                    {EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")}{" "}
                    илгээх гэж байна.
                  </>
                ) : language === "rs" ? (
                  <>
                    Ви отправляете{" "}
                    {EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")}{" "}
                    .
                  </>
                ) : (
                  <>
                    You are about to send{" "}
                    {EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")}{" "}
                  </>
                )}
              </p>

              <p className="text-[#6B7280] text-xs text-center mb-4">
                {t("feedback.emp.ask")}
              </p>
              <div
                onClick={() => {
                  setIsHidden(!isHidden);
                }}
                className="py-1 flex items-center gap-1 mb-4"
              >
                <input
                  checked={isHidden}
                  onChange={() => {
                    setIsHidden(!isHidden);
                  }}
                  className="w-[16px] rounded"
                  type="checkbox"
                />

                <p className="text-[#222] text-[13px]">
                  {t("feedback.emp.anonymous")}
                </p>
              </div>
              <button
                className="w-full py-2 flex items-center justify-center text-white bg-[#2D2D2D] rounded-xl"
                disabled={loading}
                onClick={sendBackend}
              >
                {" "}
                {loading ? (
                  <span className="spinner">
                    <FaSpinner className="spin-animation" />
                  </span>
                ) : (
                  t("feedback.emp.sent")
                )}
              </button>
            </div>
          </div>
        )}
        {popUp2 && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div ref={popUpRef2} className="bg-white rounded-xl p-6 w-[400px]">
              <p className="text-sm font-semibold opacity-70 mt-5 text-center">
                {language === "en"
                  ? `For your attention, please provide ${
                      EmpData.companyId === 31
                        ? t("feedback.feed")
                        : t("feedback.feedback")
                    } to ${data.firstName}.`
                  : language === "rs"
                  ? `Внимание тем, кто собирается дать ${
                      EmpData.companyId === 31
                        ? t("feedback.feed")
                        : t("feedback.feedback")
                    } ${data.firstName}.`
                  : `${data.firstName}-д ${
                      EmpData.companyId === 31
                        ? t("feedback.feed")
                        : t("feedback.feedback")
                    } өгөх гэж буй таны анхааралд`}
              </p>
              <div className="flex justify-center my-4">
                {EmpData.companyId === 197 ? (
                  <img
                    className="w-auto h-[90px]"
                    src="/img/feedback/inboxIconApu.png"
                    alt="icon"
                  />
                ) : (
                  <img src="/img/feedback/inboxIcon.svg" alt="icon" />
                )}
              </div>
              <div className="mt-4">
                <div className="flex  gap-2 items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    {t("feedback.emp.warning")}
                  </p>
                </div>
                <div className="flex gap-2  items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    {t("feedback.emp.warning2")}
                  </p>
                </div>
                <div className="flex gap-2  items-start mb-2">
                  <div className="w-max h-max">
                    <div
                      style={{
                        background: `${
                          EmpData.companyId === 197
                            ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                            : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                        }`,
                      }}
                      className="w-3 h-3 mt-1 rounded-full "
                    />
                  </div>
                  <p className=" text-sm opacity-70">
                    {t("feedback.emp.warning3")}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setPopUp2(false);
                  }}
                  className={`w-full py-2 text-sm font-semibold text-white  ${
                    EmpData.companyId === 197 ? "bg-[#191442]" : "bg-[#2D2D2D]"
                  } rounded-xl mt-4`}
                >
                  {t("feedback.emp.unders")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopFeedbackEmp;
