import React, { createContext, useContext, useState, useEffect } from "react";
import en from "../locales/en/translation.json";
import mn from "../locales/mn/translation.json";
import rs from "../locales/rs/translation.json";

const LanguageContext = createContext();

const translations = { en, mn, rs };

const getDefaultLanguage = () => {
  const userLanguage = navigator.language || navigator.userLanguage;
  console.log("userLanguage", userLanguage);
  if (userLanguage.startsWith("ru")) {
    return "rs";
  } else if (userLanguage.startsWith("mn")) {
    return "mn";
  } else {
    return "en";
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") || getDefaultLanguage()
  );

  useEffect(() => {
    localStorage.setItem("lang", language);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const t = (key) => {
    const keys = key.split(".");
    let result = translations[language];

    for (let k of keys) {
      result = result?.[k];
      if (result === undefined) {
        return key;
      }
    }

    return result || key;
  };

  return (
    <LanguageContext.Provider value={{ t, changeLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
