import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./partials/Login";
import LoginById from "./partials/LoginById";
import Home from "./pages/Home";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./partials/Layout";
import MyProfile from "./pages/MyProfile";
import OnBoarding from "./pages//onboard/OnBoarding";
import NotFound from "./partials/NotFound";

import { UserProvider } from "./context/userContext";
import { TitleProvider } from "./context/TitleContext";
import { SidebarProvider } from "./context/SidebarContext";
import { NightModeProvider } from "./context/NightModeContext";
import Feedback from "./pages/Feedback";
import FeedbackEmp from "./pages/feedback/FeedbackEmp";
import FeedbackSearch from "./pages/feedback/FeedbackSearch";
import Notifications from "./partials/Notifications";
import Challenges from "./pages/Challenges";
import PrizePool from "./pages/Challenges/PrizePool";
import Tasks from "./pages/Challenges/Tasks";
import Leaderboard from "./pages/Challenges/Leaderboard";
import Test from "./directed/Test";
import TestEnding from "./directed/TestEnding";
import Onboard from "./pages/Onboard";
import ControlOnboard from "./pages/onboard/ControlOnboard";
import LoginByMetacog from "./partials/LoginByMetacog";
import { LanguageProvider } from "./context/LanguageContext";
import MyCompany from "./pages/MyCompany";
import OauthPage from "./pages/Oauth";


const App = () => {
  return (
    <UserProvider>
      <SidebarProvider>
        <LanguageProvider>
          <TitleProvider>
            <NightModeProvider>
              <Router>
                <Routes>
                  <Route path="/oauth2/login" element={<OauthPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/company/:word/:id" element={<LoginById />} />
                  <Route path="/metacog_token" element={<LoginByMetacog />} />
                  <Route
                    element={
                      <PrivateRoute>
                        <Layout />
                      </PrivateRoute>
                    }
                  >
                    <Route path="/" element={<Home />} />

                    {/* profile category */}
                    <Route path="/profile" element={<MyProfile />} />

                    {/* onboard category */}
                    <Route path="/onboard" element={<Onboard />}>
                      <Route index element={<Onboard />} />
                      <Route path="my" element={<OnBoarding />} />
                      <Route path="control" element={<ControlOnboard />} />
                    </Route>

                    {/* feedback category */}
                    <Route path="/feed-forward" element={<Feedback />} />
                    <Route
                      path="/feed-forward/search"
                      element={<FeedbackSearch />}
                    />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/feed-forward/:id" element={<FeedbackEmp />} />

                    {/* challenges category */}
                    <Route path="/challenges" element={<Challenges />}>
                      <Route index element={<Challenges />} />
                      <Route path="tasks" element={<Tasks />} />
                      <Route path="leaderboard" element={<Leaderboard />} />
                      <Route path="prize" element={<PrizePool />} />
                    </Route>

                    {/* company category */}
                    <Route path="/company" element={<MyCompany />} />
                  </Route>
                  {/* test category */}
                  <Route path="/test" element={<Test />} />{" "}
                  <Route path="/test/ending" element={<TestEnding />} />{" "}
                  <Route path="*" element={<NotFound />} />{" "}
                </Routes>
              </Router>
              <ToastContainer />
            </NightModeProvider>
          </TitleProvider>
        </LanguageProvider>
      </SidebarProvider>
    </UserProvider>
  );
};

export default App;
