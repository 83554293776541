import React, { useEffect, useState } from "react";
import "../style/custom-card.css";
import { MdOutlinePhone } from "react-icons/md";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { getEmp } from "../service/feedback.service";
import Loading from "../components/common/Loading";
import { useLanguage } from "../context/LanguageContext";

const MyCompany = () => {
  const [emp, setEmp] = useState(null);
  const [pages, setPages] = useState([]);
  const { t } = useLanguage();

  useEffect(() => {
    getEmp()
      .then((data) => {
        setEmp(data.data);

        setPages(Array(data.data.length).fill("about"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (emp === null) {
    return <Loading />;
  }

  const formatBirthDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month} сарын ${day}`;
  };

  const handlePageChange = (index, newPage) => {
    const updatedPages = [...pages];
    updatedPages[index] = newPage;
    setPages(updatedPages);
  };

  return (
    <div className="mt-11 w-10/12 m-auto">
      <div className="flex flex-wrap items-start gap-6 md:justify-start justify-center mt-10 mb-20">
        {emp.map((item, index) => (
          <div key={index} className="card" data-state="#about">
            <div className="card-header flex justify-center">
              <div
                className="card-cover"
                style={{
                  backgroundImage: `url('${item.profileUrl}')`,
                }}
              ></div>

              <img className="card-avatar" src={item.profileUrl} alt="avatar" />

              <div className="absolute bottom-0">
                <p className="text-center">
                  {item.lastName} {item.firstName}
                </p>

                <p className="text-center mt-2 text-xs">{item.position}</p>
              </div>
            </div>

            <div className="card-main">
              <div
                className={`card-section ${
                  pages[index] === "about" ? "is-active" : ""
                }`}
              >
                {/* <div className="card-content">
                  <div className="card-subtitle">{t("company.hobby")}</div>

                  <p className="card-desc">
                    Whatever tattooed stumptown art party sriracha gentrify
                    hashtag intelligentsia readymade schlitz brooklyn disrupt.
                  </p>
                </div> */}

                <div className="card-social mt-10">
                  {item.socialUrl !== null && (
                    <div
                      onClick={() => {
                        window.open(item.socialUrl, "_blank");
                      }}
                      className="cursor-pointer"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z" />
                      </svg>
                    </div>
                  )}

                  {item.igUrl !== null && (
                    <div
                      onClick={() => {
                        window.open(
                          `https://www.instagram.com/${item.igUrl}`,
                          "_blank"
                        );
                      }}
                      className="cursor-pointer"
                    >
                      <svg
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M301 256c0 24.852-20.148 45-45 45s-45-20.148-45-45 20.148-45 45-45 45 20.148 45 45zm0 0" />
                        <path d="M332 120H180c-33.086 0-60 26.914-60 60v152c0 33.086 26.914 60 60 60h152c33.086 0 60-26.914 60-60V180c0-33.086-26.914-60-60-60zm-76 211c-41.355 0-75-33.645-75-75s33.645-75 75-75 75 33.645 75 75-33.645 75-75 75zm86-146c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" />
                        <path d="M377 0H135C60.562 0 0 60.563 0 135v242c0 74.438 60.563 135 135 135h242c74.438 0 135-60.563 135-135V135C512 60.562 451.437 0 377 0zm45 332c0 49.625-40.375 90-90 90H180c-49.625 0-90-40.375-90-90V180c0-49.625 40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0" />
                      </svg>
                    </div>
                  )}

                  {item.linkedinUrl !== null && (
                    <div
                      onClick={() => {
                        window.open(item.linkedinUrl, "_blank");
                      }}
                      className="cursor-pointer"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 002.882 0z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`card-section ${
                  pages[index] === "contact" ? "is-active" : ""
                }`}
              >
                <div className="card-content">
                  <div className="card-subtitle">{t("company.contact")}</div>

                  <div>
                    <div className="flex items-center gap-2 mb-4">
                      <MdOutlinePhone className="w-[20px] h-[20px]" />
                      <div className="w-[1px] h-[20px] bg-[#ccc]"></div>
                      <p className="text-sm">
                        {item.phone !== null && (
                          <>
                            {item.phone.slice(0, 4) || ""}-
                            {item.phone.slice(4) || ""}
                          </>
                        )}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 mb-4">
                      <div className="w-[20px] h-[20px] flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                          <path d="M22 6l-10 7L2 6" />
                        </svg>
                      </div>
                      <div className="w-[1px] h-[20px] bg-[#ccc]"></div>
                      <p className="text-sm">{item.email}</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-[20px] h-[20px] flex items-center justify-center">
                        <LiaBirthdayCakeSolid className="w-[20px] h-[20px]" />
                      </div>
                      <div className="w-[1px] h-[20px] bg-[#ccc]"></div>
                      <p className="text-sm">
                        {formatBirthDate(item?.birthDate) || ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-buttons">
                <button
                  onClick={() => handlePageChange(index, "about")}
                  className={`relative px-4 pt-2 text-[#2b2c48] transition-all duration-300 border-none ${
                    pages[index] === "about" ? "font-semibold" : ""
                  }`}
                >
                  {t("company.about")}
                  <span
                    className={`absolute mt-2 inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent via-[#cfcaff33] to-[#d3e2ff66] opacity-0 transition-opacity duration-300 ${
                      pages[index] === "about"
                        ? "border-b-4 border-[#324d72] opacity-100"
                        : "hover:opacity-100 hover:border-b-4 border-[#324d72]"
                    }`}
                  ></span>
                </button>

                <button
                  onClick={() => handlePageChange(index, "contact")}
                  className={`relative px-4 pt-2 text-[#2b2c48] transition-all duration-300 border-none ${
                    pages[index] === "contact" ? "font-semibold" : ""
                  }`}
                >
                  {t("company.contact")}
                  <span
                    className={`absolute mt-2 inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent via-[#cfcaff33] to-[#d3e2ff66] opacity-0 transition-opacity duration-300 ${
                      pages[index] === "contact"
                        ? "border-b-4 border-[#324d72] opacity-100"
                        : "hover:opacity-100 hover:border-b-4 border-[#324d72]"
                    }`}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyCompany;
