import api from "../helper/request";

const login = (obj) => api.post("/employees/login", obj);

const getUserData = () => api.get("/employees/info");

const loginById = (id) => api.post(`/employees/login/empId?empId=${id}`);

const getNotf = () => api.get(`/employees/assign/detail`);

const editUser = (obj) => api.post(`/employees/update`, obj);

const getHobby = () => api.get("/hobby");

const createHobby = (obj) => api.post("/employee-hobby", obj);

const delHobby = (ids) => api.delete(`/employee-hobby/delete`, { data: ids });

const creatingAiImg = (obj) => api.post(`/fal-ai/create`, obj);

const loginMicrosoft = (obj) => api.post("/employees/microsoft/login", obj);

export {
  login,
  getUserData,
  loginById,
  getNotf,
  editUser,
  getHobby,
  createHobby,
  delHobby,
  creatingAiImg,
  loginMicrosoft
};
