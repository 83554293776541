import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { NightModeContext } from "../context/NightModeContext";
import { useSidebar } from "../context/SidebarContext";

const Layout = () => {
  const { nightMode, setNightMode } = useContext(NightModeContext);
  const { sidebar, setSidebar } = useSidebar();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/onboard") {
      setNightMode(false);
    }
    if (location.pathname !== "/profile") {
      setSidebar("open");
    }
  }, [location, setNightMode, setSidebar]);

  return (
    <div
      className={`flex w-full overflow-hidden min-h-[calc(var(--vh,1vh)*100)] bg-[#${
        nightMode ? "2E1B5B" : "F4F6FB"
      }]`}
    >
      {location.pathname !== "/profile" && sidebar === "open" && (
        <div className="md:block sm:hidden hidden max-w-[20rem]  fixed">
          <Sidebar />
        </div>
      )}
      <div
        className={`relative flex flex-col flex-1 mt-20 ${
          sidebar === "open" ? "md:ml-[20rem]" : "ml-0"
        }`}
      >
        <Header />
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
