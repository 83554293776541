import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa6";
import "../../../style/animation.css";
import { toast } from "react-toastify";
import { createFeedback } from "../../../service/feedback.service";
import { useLanguage } from "../../../context/LanguageContext";

const MobileFeedbackEmp = ({ EmpData }) => {
  const [data, setData] = useState(null);
  const [modal, setModal] = useState(0);
  const modalRef = useRef();
  const { t, language } = useLanguage();
  const [focus, setFocus] = useState(1);

  const [btn, setBtn] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  // modal
  const [popUp, setPopUp] = useState(false);

  //start
  const [startWrite, setStartWrite] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //continue
  const [continueWrite, setContinueWrite] = useState("");
  const [shouldAnimate2, setShouldAnimate2] = useState(false);

  //stop
  const [stopWrite, setStopWrite] = useState("");
  const [stopEvent, setStopEvent] = useState("");
  const [shouldAnimate3, setShouldAnimate3] = useState(false);

  //popup2
  const [popUp2, setPopUp2] = useState(true);
  const popUpRef2 = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popUp2 &&
        popUpRef2.current &&
        !popUpRef2.current.contains(event.target)
      ) {
        setPopUp2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp2]);

  useEffect(() => {
    if (EmpData !== undefined || EmpData !== null) {
      setData(EmpData);
    }
  }, [EmpData]);

  useEffect(() => {
    let timer;
    if (startWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 1000);
    } else {
      setShouldAnimate(false);
    }
    return () => clearTimeout(timer);
  }, [startWrite]);

  useEffect(() => {
    let timer;
    if (continueWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate2(true);
      }, 1000);
    } else {
      setShouldAnimate2(false);
    }
    return () => clearTimeout(timer);
  }, [continueWrite]);

  useEffect(() => {
    let timer;
    if (stopWrite !== "") {
      setBtn(true);
      timer = setTimeout(() => {
        setShouldAnimate3(true);
      }, 1000);
    } else {
      setShouldAnimate3(false);
    }
    return () => clearTimeout(timer);
  }, [stopWrite]);

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal(0);
    }
  };

  if (data === undefined) {
    return (
      <div className="h-screen m-auto">
        <p className="text-[32px]">Employee not found</p>
      </div>
    );
  }

  if (data === null) return <div>Loading...</div>;

  const sendBackend = async () => {
    setLoading(true);
    try {
      let startMsg;
      let continueMsg;
      let stopMsg;

      if (startWrite !== "") {
        startMsg = startWrite;
      }

      if (continueWrite !== "") {
        continueMsg = continueWrite;
      }

      if (stopWrite !== "") {
        stopMsg = stopWrite;
      }

      const types = [];

      if (startMsg !== undefined) {
        types.push({
          type: "START",
          text: startMsg,
        });
      }

      if (continueMsg !== undefined) {
        types.push({
          type: "CONTINUE",
          text: continueMsg,
        });
      }

      if (stopMsg !== undefined) {
        types.push({
          type: "STOP",
          text: stopMsg,
          feedbackCase: stopEvent,
        });
      }

      const backendMsg = {
        receivingEmployeeId: EmpData.id,

        hide: isHidden,
        types,
      };

      await createFeedback(backendMsg)
        .then(() => {
          toast.success("Success");
          setLoading(false);
          navigate("/feed-forward");
        })
        .catch((e) => {
          setLoading(false);
          toast.error(t("feedback.emp.err"));
        });

      setLoading(false);
      // console.log("Success:", backendMsg);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="relative">
      <div className="relative flex items-center justify-center  ">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover rounded-b-[50px] shadow-xl"
          src={`${
            EmpData.companyId === 197 ? "/img/bgImgApu.svg" : "/img/bgImg.svg"
          }`}
          alt="img"
        />
        {/* <img
          className="absolute top-0 w-full h-[200px] left-0"
          src="/img/Container.svg"
          alt="bg"
        /> */}
        <div className=" relative mt-10 flex flex-col items-center">
          <div className="w-[60px] h-[60px] rounded-full border border-[#ccc]">
            <img
              className="w-full h-full object-cover rounded-full"
              src={data.profileUrl}
              alt="aaa"
            />
          </div>
          <p
            className={`text-center ${
              EmpData.companyId === 197 ? "text-[#fff] " : "text-[#000]"
            } text-[20px] font-semibold mt-2`}
            style={
              EmpData.companyId === 197
                ? {}
                : {
                    textShadow: "3px 3px 3px #fff",
                  }
            }
          >
            {data.firstName}, {data.lastName}
          </p>
          <p
            className={`text-center ${
              EmpData.companyId === 197 ? "text-[#fff] " : "text-[#000]"
            } text-[16px] mb-4`}
            style={
              EmpData.companyId === 197
                ? {}
                : {
                    textShadow: "1px 1px 1px #fff",
                  }
            }
          >
            {data.departmentName}, {data.position}
          </p>
        </div>
      </div>
      <div className="mt-7 px-4">
        <p className="text-xs text-[#222] text-center">
          {language === "mn" ? (
            <>
              Дараах 3 төрлөөр {data.position} {data.firstName} -д өгнө үү.{" "}
            </>
          ) : language === "rs" ? (
            <>
              Пожалуйста, предоставьте следующие 3 типа для {data.position}{" "}
              {data.firstName}.
            </>
          ) : (
            <>
              Please provide the following 3 types for {data.position}{" "}
              {data.firstName}.
            </>
          )}
        </p>
        <div className="my-6">
          <div className="flex justify-start">
            <div
              onClick={() => {
                setFocus(1);
                setModal(1);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate ? "fill-animation-bottom-left" : ""
              }`}
              style={{
                background: shouldAnimate ? "" : "white",
              }}
            >
              {EmpData.companyId === 197 ? (
                <img
                  className="h-[50px] w-auto"
                  src="/img/feedback/icons/bIcon1Apu.png"
                  alt="icon"
                />
              ) : (
                <img src="/img/feedback/icons/bIcon1.svg" alt="icon" />
              )}
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                START
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                {language === "mn" ? (
                  <>
                    Цаашид юуг{" "}
                    <span className="font-semibold">хэрэгжүүлж эхэлбэл</span>{" "}
                    зохистой тухай зөвлөнө үү.
                  </>
                ) : language === "rs" ? (
                  <>
                    На что следует{" "}
                    <span className="font-semibold">
                      начать обращать внимание
                    </span>{" "}
                    коллеге?
                  </>
                ) : (
                  <>
                    What should be{" "}
                    <span className="font-semibold">started implementing</span>{" "}
                    going forward?
                  </>
                )}
              </p>
            </div>
          </div>

          <div className="flex justify-end">
            <div
              onClick={() => {
                setFocus(1);
                setModal(3);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate3 ? "fill-animation-bottom-top" : ""
              }`}
              style={{
                background: shouldAnimate3 ? "" : "white",
              }}
            >
              {EmpData.companyId === 197 ? (
                <img
                  className="h-[50px] w-auto"
                  src="/img/feedback/icons/bIcon3Apu.png"
                  alt="icon"
                />
              ) : (
                <img src="/img/feedback/icons/bIcon3.svg" alt="icon" />
              )}
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate3 ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                STOP{" "}
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate3 ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                {language === "mn" ? (
                  <>
                    Цаашид юуг{" "}
                    <span className="font-semibold">хийхээ зогсоовол</span>{" "}
                    зохистой тухай зөвлөнө үү.
                  </>
                ) : language === "rs" ? (
                  <>
                    Что нужно <span className="font-semibold">прекратить</span>{" "}
                    в коллеге?
                  </>
                ) : (
                  <>
                    What should be{" "}
                    <span className="font-semibold">stopped doing</span> going
                    forward?
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-start">
            <div
              onClick={() => {
                setFocus(1);
                setModal(2);
              }}
              className={`border border-[#C6C6C6] rounded-full w-[190px] h-[190px] flex flex-col items-center justify-center pb-3 ${
                shouldAnimate2 ? "fill-animation-bottom-top-left" : ""
              }`}
              style={{
                background: shouldAnimate2 ? "" : "white",
              }}
            >
              {EmpData.companyId === 197 ? (
                <img
                  className="h-[50px] w-auto"
                  src="/img/feedback/icons/bIcon2Apu.png"
                  alt="icon"
                />
              ) : (
                <img src="/img/feedback/icons/bIcon2.svg" alt="icon" />
              )}
              <p
                className={`text-[20px] font-semibold mt-2 ${
                  shouldAnimate2 ? "text-white-animation" : "text-[#636466]"
                }`}
              >
                Continue
              </p>
              <p
                className={`text-xs text-center w-[152px] ${
                  shouldAnimate2 ? "text-white-animation" : "text-[#A1A1A1]"
                }`}
              >
                {language === "mn" ? (
                  <>
                    Цаашид юуг{" "}
                    <span className="font-semibold">
                      үргэлжлүүлэн хэрэгжүүлвэл
                    </span>{" "}
                    зохистой тухай зөвлөнө үү.
                  </>
                ) : language === "rs" ? (
                  <>
                    Что следует{" "}
                    <span className="font-semibold">продолжать делать</span>{" "}
                    коллеге?
                  </>
                ) : (
                  <>
                    What should be{" "}
                    <span className="font-semibold">
                      continued implementing
                    </span>{" "}
                    going forward?
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mx-6 mb-6">
          {btn && (
            <button
              onClick={() => {
                setPopUp(true);
              }}
              className="px-6  py-2  w-[300px]  text-sm text-white font-semibold bg-[#222] rounded-lg"
            >
              {t("feedback.emp.sent")}
            </button>
          )}
        </div>
      </div>
      {modal === 1 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src={`${
                  EmpData.companyId === 197
                    ? "/img/feedback/icons/bIcon1Apu.png"
                    : "/img/feedback/icons/bIcon1.svg"
                }`}
                alt="icon"
              />
              <p className="font-semibold">Start</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              {language === "mn" ? (
                <>
                  Таны өгсөн{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}
                  тухайн ажилтны ажлын бүтээмж, сэтгэлзүйд шууд нөлөөлөх
                  боломжтой тул та эелдгээр зохистой, хэрэгцээтэй зөвлөмжийг
                  өгнө үү.
                </>
              ) : language === "en" ? (
                <>
                  Your{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  can directly influence the productivity and mental state of
                  the employee, so please provide polite and appropriate
                  suggestions.
                </>
              ) : (
                <>
                  Ваш{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  может напрямую повлиять на производительность и
                  психоэмоциональное состояние сотрудника, поэтому, пожалуйста,
                  предоставляйте его в вежливой и уместной форме.
                </>
              )}
            </p>
            <div className="mt-4">
              <div className="flex items-start gap-2 mb-2">
                <div
                  className={`h-4 w-4 rounded-full border-2 mt-2 ${
                    focus === 3 ? "border-[#0752DF]" : "border-gray-400"
                  }`}
                />
                <div>
                  <div
                    className={`w-[300px] border px-2 py-2 rounded-xl mb-3 ${
                      focus === 3
                        ? EmpData.companyId === 197
                          ? "border-[#41E6FF]"
                          : "border-[#0752DF]"
                        : "border-gray-300"
                    }`}
                  >
                    <p
                      className={`text-xs ${
                        focus === 3
                          ? EmpData.companyId === 197
                            ? "text-[#191442]"
                            : "text-[#0752DF]"
                          : "text-gray-400"
                      }`}
                    >
                      {EmpData.companyId === 31
                        ? t("feedback.feed")
                        : t("feedback.feedback")}{" "}
                    </p>

                    <textarea
                      id="feedback"
                      name="feedback"
                      onChange={(e) => {
                        setStartWrite(e.target.value);
                      }}
                      onClick={() => setFocus(3)}
                      rows="3"
                      className="mt-1 block w-full text-[12px] h-[60px]"
                      placeholder={t("feedback.emp.write")}
                      value={startWrite}
                    ></textarea>
                  </div>
                </div>
              </div>
              {startWrite !== "" ? (
                <button
                  onClick={() => {
                    setFocus(1);
                    setModal(0);
                  }}
                  className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                >
                  {t("feedback.emp.co")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {modal === 2 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src={`${
                  EmpData.companyId === 197
                    ? "/img/feedback/icons/bIcon2Apu.png"
                    : "/img/feedback/icons/bIcon2.svg"
                }`}
                alt="icon"
              />
              <p className="font-semibold">Continue</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              {language === "mn" ? (
                <>
                  Тухайн ажилтны одоо хийж байгаа бүтэмжтэй, таатай хандлагыг
                  урамшуулж цаашдаа энэхүү хандлагаа тогтоон барихад туслах{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  бичнэ үү.
                </>
              ) : language === "rs" ? (
                <>
                  Похвалите текущую продуктивность и позитивный настрой этого
                  человека и помогите ему сохранить этот настрой, написав{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  .
                </>
              ) : (
                <>
                  Encourage the employee's current productivity and positive
                  attitude, and help them maintain this attitude by writing{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  .
                </>
              )}
            </p>
            <div className="mt-4">
              <div className="flex items-start gap-2 mb-2">
                <div
                  className={`h-4 w-4 rounded-full border-2 mt-2 ${
                    focus === 3 ? "border-[#0752DF]" : "border-gray-400"
                  }`}
                />
                <div>
                  <div
                    className={`w-[300px] border px-2 py-2 rounded-xl mb-3 ${
                      focus === 3
                        ? EmpData.companyId === 197
                          ? "border-[#41E6FF]"
                          : "border-[#0752DF]"
                        : "border-gray-300"
                    }`}
                  >
                    <p
                      className={`text-xs ${
                        focus === 3
                          ? EmpData.companyId === 197
                            ? "text-[#191442]"
                            : "text-[#0752DF]"
                          : "text-gray-400"
                      }`}
                    >
                      {EmpData.companyId === 31
                        ? t("feedback.feed")
                        : t("feedback.feedback")}{" "}
                    </p>

                    <textarea
                      id="feedback"
                      name="feedback"
                      onChange={(e) => {
                        setContinueWrite(e.target.value);
                      }}
                      onClick={() => setFocus(3)}
                      rows="3"
                      className="mt-1 block w-full text-[12px] h-[60px]"
                      placeholder={t("feedback.emp.write")}
                      value={continueWrite}
                    ></textarea>
                  </div>
                </div>
              </div>
              {continueWrite !== "" ? (
                <button
                  onClick={() => {
                    setModal(0);
                  }}
                  className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                >
                  {t("feedback.emp.co")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {modal === 3 && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-8 px-4">
            <div className="flex items-center gap-2">
              <img
                className="h-[48px] w-[48px]"
                src={`${
                  EmpData.companyId === 197
                    ? "/img/feedback/icons/bIcon3Apu.png"
                    : "/img/feedback/icons/bIcon3.svg"
                }`}
                alt="icon"
              />
              <p className="font-semibold">STOP</p>
            </div>
            <p className="text-xs opacity-70 w-[325px] mt-2">
              {language === "mn" ? (
                <>
                  Тухайн ажилтны одоо гаргаж буй таагүй, цухалдуулам хандлага,
                  үйлдлийг зогсооход чиглэсэн{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  өгөх ба ингэснээрээ ямар үр дүнд хүрж болохыг эелдгээр
                  илэрхийлнэ үү.
                </>
              ) : language === "rs" ? (
                <>
                  Укажите на текущее негативное и провокационное поведение и
                  поступки человека, предложите{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  , который поможет в их исправлении, а также опишите возможные
                  результаты таких изменений.
                </>
              ) : (
                <>
                  Address the employee's current negative and provocative
                  behaviors and actions, and provide{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  to help with this, along with the potential outcomes it could
                  bring.
                </>
              )}
            </p>

            <div className="mt-4">
              <div className="flex items-start gap-2 mb-2">
                <div
                  className={`h-4 w-4 rounded-full border-2 mt-2 ${
                    focus === 2 ? "border-[#0752DF]" : "border-gray-400"
                  }`}
                />
                <div>
                  <div
                    className={`w-[300px] border px-2 py-2 rounded-xl mb-3 ${
                      focus === 2
                        ? EmpData.companyId === 197
                          ? "border-[#41E6FF]"
                          : "border-[#0752DF]"
                        : "border-gray-300"
                    }`}
                  >
                    <p
                      className={`text-xs ${
                        focus === 2
                          ? EmpData.companyId === 197
                            ? "text-[#191442]"
                            : "text-[#0752DF]"
                          : "text-gray-400"
                      }`}
                    >
                      {t("feedback.emp.sbi")}{" "}
                    </p>

                    <textarea
                      id="feedback"
                      name="feedback"
                      onChange={(e) => {
                        setStopEvent(e.target.value);
                      }}
                      onClick={() => setFocus(2)}
                      rows="3"
                      className="mt-1 block w-full text-[12px] h-[80px]"
                      placeholder={t("feedback.emp.sbiplc")}
                      value={stopEvent}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="flex items-start gap-2 mb-2">
                <div
                  className={`h-4 w-4 rounded-full border-2 mt-2 ${
                    focus === 3 ? "border-[#0752DF]" : "border-gray-400"
                  }`}
                />
                <div>
                  <div
                    className={`w-[300px] border px-2 py-2 rounded-xl mb-3 ${
                      focus === 3
                        ? EmpData.companyId === 197
                          ? "border-[#41E6FF]"
                          : "border-[#0752DF]"
                        : "border-gray-300"
                    }`}
                  >
                    <p
                      className={`text-xs ${
                        focus === 3
                          ? EmpData.companyId === 197
                            ? "text-[#191442]"
                            : "text-[#0752DF]"
                          : "text-gray-400"
                      }`}
                    >
                      {t("feedback.emp.acc")}{" "}
                    </p>

                    <textarea
                      id="feedback"
                      name="feedback"
                      onChange={(e) => {
                        setStopWrite(e.target.value);
                      }}
                      onClick={() => setFocus(3)}
                      rows="3"
                      className="mt-1 block w-full text-[12px] h-[80px]"
                      placeholder={t("feedback.emp.accplc")}
                      value={stopWrite}
                    ></textarea>
                  </div>
                </div>
              </div>
              {stopWrite !== "" ? (
                <button
                  onClick={() => {
                    setModal(0);
                  }}
                  className="w-full rounded-xl mx-3 mt-3 py-3 text-white bg-[#222] text-xs"
                >
                  {t("feedback.emp.co")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {popUp && (
        <div
          onClick={handleModalClickOutside}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div ref={modalRef} className="bg-white rounded-xl py-4 px-4">
            <p className="text-center text-[#444] text-sm font-semibold mb-1">
              {language === "mn" ? (
                <>
                  Та{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  илгээх гэж байна.
                </>
              ) : language === "rs" ? (
                <>
                  Ви отправляете{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                  .
                </>
              ) : (
                <>
                  You are about to send{" "}
                  {EmpData.companyId === 31
                    ? t("feedback.feed")
                    : t("feedback.feedback")}{" "}
                </>
              )}
            </p>
            <p className="text-[#6B7280] text-xs text-center mb-4">
              {t("feedback.emp.ask")}
            </p>
            <div
              onClick={() => {
                setIsHidden(!isHidden);
              }}
              className="py-1 flex items-center gap-1 mb-4"
            >
              <input
                checked={isHidden}
                onChange={() => {
                  setIsHidden(!isHidden);
                }}
                className="w-[16px] rounded"
                type="checkbox"
              />

              <p className="text-[#222] text-[13px]">
                {" "}
                {t("feedback.emp.anonymous")}
              </p>
            </div>
            <button
              className="w-full py-2 flex items-center justify-center text-white bg-[#2D2D2D] rounded-xl"
              onClick={sendBackend}
              disabled={loading}
            >
              {loading ? (
                <span className="spinner">
                  <FaSpinner className="spin-animation" />
                </span>
              ) : (
                t("feedback.emp.sent")
              )}
            </button>
          </div>
        </div>
      )}
      {popUp2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div ref={popUpRef2} className="bg-white rounded-xl p-6 w-[340px]">
            <p className="text-sm font-semibold opacity-70 mt-5 text-center">
              {language === "en"
                ? `For your attention, please provide ${
                    EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")
                  } to ${data.firstName}.`
                : language === "rs"
                ? `Внимание тем, кто собирается дать ${
                    EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")
                  } ${data.firstName}.`
                : `${data.firstName}-д ${
                    EmpData.companyId === 31
                      ? t("feedback.feed")
                      : t("feedback.feedback")
                  } өгөх гэж буй таны анхааралд`}
            </p>
            <div className="flex justify-center my-4">
              {EmpData.companyId === 197 ? (
                <img
                  className="w-auto h-[90px]"
                  src="/img/feedback/inboxIconApu.png"
                  alt="icon"
                />
              ) : (
                <img src="/img/feedback/inboxIcon.svg" alt="icon" />
              )}
            </div>
            <div className="mt-4">
              <div className="flex  gap-2 items-start mb-2">
                <div className="w-max h-max">
                  <div
                    style={{
                      background: `${
                        EmpData.companyId === 197
                          ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                          : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                      }`,
                    }}
                    className="w-3 h-3 mt-1 rounded-full "
                  />
                </div>
                <p className=" text-sm opacity-70">
                  {t("feedback.emp.warning")}
                </p>
              </div>
              <div className="flex gap-2  items-start mb-2">
                <div className="w-max h-max">
                  <div
                    style={{
                      background: `${
                        EmpData.companyId === 197
                          ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                          : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                      }`,
                    }}
                    className="w-3 h-3 mt-1 rounded-full "
                  />
                </div>
                <p className=" text-sm opacity-70">
                  {t("feedback.emp.warning2")}
                </p>
              </div>
              <div className="flex gap-2  items-start mb-2">
                <div className="w-max h-max">
                  <div
                    style={{
                      background: `${
                        EmpData.companyId === 197
                          ? "linear-gradient(90deg, #33EF76, #4FF98C)"
                          : "linear-gradient(90deg, #6254FF, #5B40FF, #2400FF)"
                      }`,
                    }}
                    className="w-3 h-3 mt-1 rounded-full "
                  />
                </div>
                <p className=" text-sm opacity-70">
                  {t("feedback.emp.warning3")}
                </p>
              </div>
              <button
                onClick={() => {
                  setPopUp2(false);
                }}
                className={`w-full py-2 text-sm font-semibold text-white  ${
                  EmpData.companyId === 197 ? "bg-[#191442]" : "bg-[#2D2D2D]"
                } rounded-xl mt-4`}
              >
                {t("feedback.emp.unders")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileFeedbackEmp;
