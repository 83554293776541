function generateRandomString(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    for (let i = 0; i < randomValues.length; i++) {
        result += charset.charAt(randomValues[i] % charset.length);
    }
    return result;
}

// Helper: Convert ArrayBuffer to Base64 string
function arrayBufferToBase64(buffer) {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.length; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

// Helper: Generate the code challenge from the code verifier
async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64String = arrayBufferToBase64(digest);
    // Convert Base64 to Base64-URL: replace '+' with '-', '/' with '_', and remove '='
    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Main function: Build the authorization URL with PKCE parameters
export async function buildAuthUrl() {
    const clientId = "1d572366-4e37-4688-9615-f640a1ac7bc3"; // Azure Client ID
    const redirectUri = "https://profile.oneplace.hr/oauth2/login"; // Must match Azure app settings
    // const redirectUri = "http://localhost:3000/oauth2/login";
    const scope = "openid profile email user.read"; // Required scopes
    const responseType = "code"; // Authorization Code Flow
    const responseMode = "query"; // Return response as URL query params
    const state = crypto.randomUUID(); // Generate unique state value for security

    // Generate PKCE parameters
    const codeVerifier = generateRandomString(128);
    sessionStorage.setItem("pkce_code_verifier", codeVerifier); // Store for token exchange
    sessionStorage.setItem("pkce_state", state); // Store state for CSRF protection
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Construct authorization URL
    const authUrl = new URL(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize`);
    authUrl.searchParams.set("client_id", clientId);
    authUrl.searchParams.set("response_type", responseType);
    authUrl.searchParams.set("redirect_uri", redirectUri);
    authUrl.searchParams.set("response_mode", responseMode);
    authUrl.searchParams.set("scope", scope);
    authUrl.searchParams.set("state", state);
    authUrl.searchParams.set("code_challenge", codeChallenge);
    authUrl.searchParams.set("code_challenge_method", "S256");

    return authUrl.toString();
}