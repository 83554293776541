import React, { useContext, useEffect, useRef, useState } from "react";
import { getNotf } from "../service/user.service";
import Skeleton from "react-loading-skeleton";
import { GoBellFill } from "react-icons/go";

import { NightModeContext } from "../context/NightModeContext";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";

const Notf = () => {
  const [notf, setNotf] = useState(false);
  const [notfData, setNotfData] = useState(null);
  const [table, setTable] = useState("active");
  const [unNotfData, setUnNotfData] = useState(null);
  const [notfHover, setNotfHover] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [notfInt, setNotfInt] = useState(null);
  const notfRef = useRef(null);
  const { nightMode } = useContext(NightModeContext);
  const { t } = useLanguage();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getNotf().then((data) => {
      const allData = [
        ...data.data.surveys.map((item) => ({ ...item, type: "Survey" })),
        ...data.data.burnouts.map((item) => ({ ...item, type: "Burnout" })),
        ...data.data.onas.map((item) => ({ ...item, type: "Ona" })),
      ];

      const finished = allData.filter((item) => item.isFinished);
      const unfinished = allData.filter((item) => !item.isFinished);

      const sortByDate = (a, b) => {
        if (!a.createdDate) return 1;
        if (!b.createdDate) return -1;
        return new Date(b.createdDate) - new Date(a.createdDate);
      };

      setNotfInt(unfinished.length);

      finished.sort(sortByDate);
      unfinished.sort(sortByDate);

      const notf = [...unfinished].map((item) => ({
        ...item,
        timeAgo: item.createdDate ? findWhen(item.createdDate) : "No date",
      }));
      const unNotf = [...finished].map((item) => ({
        ...item,
        timeAgo: item.createdDate ? findWhen(item.createdDate) : "No date",
      }));

      setNotfData(notf);
      setUnNotfData(unNotf);
    });
  }, []);

  const findWhen = (createdDate) => {
    const now = new Date();
    const created = new Date(createdDate);
    const diffInMs = now - created;

    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInMonths >= 1) {
      return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    } else if (diffInDays >= 1) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notfRef.current && !notfRef.current.contains(event.target)) {
        setNotf(false);
      }
    };

    if (notf) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notf]);

  return (
    <div className="relative" ref={notfRef}>
      <button
        className={`p-2 rounded-full flex items-center justify-center ${
          nightMode ? "text-[#fff] bg-[#2e1b5b]" : "text-[#666]  bg-[#E2E5E9]"
        }`}
        onClick={() => {
          if (isMobile) {
            navigate("/notifications");
          } else {
            setNotf(!notf);
          }
        }}
      >
        <GoBellFill className="text-lg" />
      </button>
      <div className="absolute text-[#fff] w-[18px] h-[18px] flex items-center justify-center text-[10px] rounded-full -top-1 -right-1 bg-[#FD6B3A]">
        {notfInt === null ? 0 : notfInt > 99 ? "+99" : notfInt}
      </div>
      {notf && (
        <div
          className={`absolute -left-40 top-8 mt-2 px-2 py-2 max-h-[600px] overflow-y-scroll w-[332px] bg-white border border-gray-200 rounded-md z-30 shadow-lg ${
            nightMode ? "bg-[#372763]" : "bg-white"
          }`}
        >
          <div className="flex items-center mb-4">
            <p className="text-[#293951] font-semibold flex items-center gap-1">
              <GoBellFill />
              {t("notf.notfs")}
            </p>
          </div>
          <div className="flex items-center mb-4">
            <button
              onClick={() => setTable("active")}
              className={`${
                table === "active"
                  ? "text-[#324D72] font-semibold border-b-2 border-[#324D72]"
                  : "text-[#666874]"
              } text-xs w-[50%]`}
            >
              {t("notf.active")} [{notfData.length}]
            </button>
            <button
              onClick={() => setTable("unactive")}
              className={`${
                table === "unactive"
                  ? "text-[#324D72] font-semibold border-b-2 border-[#324D72]"
                  : "text-[#666874]"
              } text-xs w-[50%]`}
            >
              {t("notf.inactive")} [{unNotfData.length}]
            </button>
          </div>

          {notfData === null || unNotfData === null ? (
            <div className="w-full">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="flex items-center mb-3">
                  <Skeleton circle={true} height={40} width={40} />
                  <div className="ml-2">
                    <Skeleton width={200} />
                    <Skeleton width={150} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full">
              {table === "active" ? (
                notfData.length > 0 ? (
                  notfData.map((items, index) => (
                    <div
                      key={index + 1}
                      onMouseEnter={() => setNotfHover(index + 1)}
                      onMouseLeave={() => setNotfHover(0)}
                      onClick={() => window.open(items.url, "_blank")}
                      className={`px-2 flex items-center gap-2 w-full h-[50px] rounded-xl cursor-pointer relative ${
                        notfHover === index + 1
                          ? "bg-[#cfcfcf] bg-opacity-30"
                          : "bg-none"
                      }`}
                    >
                      <div className="h-[40px] w-[10%] flex items-center justify-center">
                        <div className="w-[28px] h-[28px] flex items-center justify-center rounded-full bg-[#CDE8FF]">
                          <img
                            src={`/img/notf/${
                              items.type === "Feedback"
                                ? "feedback.svg"
                                : "notf.svg"
                            }`}
                            alt="surv"
                          />
                        </div>
                      </div>
                      <div className="w-[90%] overflow-hidden">
                        <div className="flex items-center justify-between gap-2">
                          <p
                            className="text-xs overflow-hidden text-[#1E293B]"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              lineHeight: "1.2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {items.name}
                          </p>
                          <p className="text-[#777985] text-[10px]">
                            {items.timeAgo}
                          </p>
                        </div>
                        <p className="m-0 text-[10px] text-[#777985]">
                          {items.type === "Feedback"
                            ? t("notf.feed")
                            : items.type === "Survey"
                            ? t("notf.surv")
                            : items.type === "Ona"
                            ? t("notf.ona")
                            : items.type === "Burnout"
                            ? t("notf.burn")
                            : t("notf.onboard")}
                        </p>
                      </div>
                      {!items.isFinished && (
                        <div className="h-[7px] w-[7px] rounded-full bg-[#324D72] absolute top-7 right-2 -translate-y-1/2" />
                      )}
                    </div>
                  ))
                ) : (
                  <div className=" my-10 flex items-center justify-center">
                    <div>
                      <div className="flex items-center justify-center">
                        <img src="/img/notf/empty.svg" alt="empty" />
                      </div>
                      <p className="text-center text-sm text-[#1E293B]">
                        {t("notf.not")}
                      </p>
                    </div>
                  </div>
                )
              ) : unNotfData.length > 0 ? (
                unNotfData.map((items, index) => (
                  <div
                    key={index + 1}
                    onMouseEnter={() => setNotfHover(index + 1)}
                    onMouseLeave={() => setNotfHover(0)}
                    onClick={() => window.open(items.url, "_blank")}
                    className={`px-2 flex items-center gap-2 w-full h-[50px] rounded-xl cursor-pointer relative ${
                      notfHover === index + 1
                        ? "bg-[#cfcfcf] bg-opacity-30"
                        : "bg-none"
                    }`}
                  >
                    <div className="h-[40px] w-[10%] flex items-center justify-center">
                      <div className="w-[28px] h-[28px] flex items-center justify-center rounded-full bg-[#CDE8FF]">
                        <img
                          src={`/img/notf/${
                            items.type === "Feedback"
                              ? "feedback.svg"
                              : "notf.svg"
                          }`}
                          alt="surv"
                        />
                      </div>
                    </div>
                    <div className="w-[90%] overflow-hidden">
                      <div className="flex items-center justify-between gap-2">
                        <p
                          className="text-xs overflow-hidden text-[#1E293B] text-opacity-50"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            lineHeight: "1.2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {items.name}
                        </p>
                        <p className="text-[#777985] text-[10px]">
                          {items.timeAgo}
                        </p>
                      </div>
                      <p className="m-0 text-[10px] text-[#777985]">
                        {items.type === "Feedback"
                          ? t("notf.feed")
                          : items.type === "Survey"
                          ? t("notf.surv")
                          : items.type === "Ona"
                          ? t("notf.ona")
                          : items.type === "Burnout"
                          ? t("notf.burn")
                          : t("notf.onboard")}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" my-10 flex items-center justify-center">
                  <div>
                    <div className="flex items-center justify-center">
                      <img src="/img/notf/empty.svg" alt="empty" />
                    </div>
                    <p className="text-center text-sm text-[#1E293B]">
                      {t("notf.not")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notf;
