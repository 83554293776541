import React, { useContext, useEffect, useState } from "react";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosSearch,
  IoMdClose,
} from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { MdOutlineCake, MdPerson } from "react-icons/md";
import { MdOutlineCameraAlt } from "react-icons/md";
import "../style/modal-animation.css";
import {
  createHobby,
  creatingAiImg,
  editUser,
  getHobby,
} from "../service/user.service";
import { UserContext } from "../context/userContext";
import { takePicture } from "../service/onboard.service";
import { useLanguage } from "../context/LanguageContext";
import { toast } from "react-toastify";
import { IoExitOutline } from "react-icons/io5";

const Modal = ({ num, modal, setModal }) => {
  const [hobby, setHobby] = useState(null);
  const { user, setUser, setRefresh } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [birthday, setBirthday] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [image, setImage] = useState("/img/modal/user.svg");
  const [splash, setSplash] = useState(true);
  const [creatingLoad, setCreatingLoad] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState(null);
  const [animationEnd, setAnimationEnd] = useState(false);
  const { t } = useLanguage();
  const [chosen, setChosen] = useState(null);
  const [aiImg, setAiImg] = useState(null);

  const slides = [
    {
      name: "Оффисын ажилтан",
      images: ["/img/modal/apu/female3.webp", "/img/modal/apu/male3.webp"],
    },
    {
      name: "Үйлдвэрийн ажилтан",
      images: ["/img/modal/apu/female2.webp", "/img/modal/apu/male2.webp"],
    },
    {
      name: "Парк",
      images: ["/img/modal/apu/female1.webp", "/img/modal/apu/male1.webp"],
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentSlide = slides[currentIndex];

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    setPage(num);
  }, [num]);

  useEffect(() => {
    const splashTimer = setInterval(() => setSplash(false), 2000);
    return () => clearInterval(splashTimer);
  }, []);

  useEffect(() => {
    const splashTimer = setInterval(() => setAnimationEnd(true), 5300);
    return () => clearInterval(splashTimer);
  }, []);

  useEffect(() => {
    if (user?.profileUrl !== null) {
      setImage(user?.profileUrl);
    }
  }, [user]);

  useEffect(() => {
    getHobby()
      .then((data) => {
        setHobby(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleImageCapture = async (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setError(t("modal.err"));
        setImage(null);
      } else {
        setLoadingImage(true);
        setError(null);

        try {
          const data = await takePicture(file);
          setImage(data.data);
          setLoadingImage(false);
        } catch (err) {
          setError(t("modal.err2"));
          setLoadingImage(false);
        }
      }
    }
  };

  const handleAiImageCapture = async (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setError(t("modal.err"));
        setAiImg(null);
      } else {
        setLoadingImage(true);
        setError(null);

        try {
          const data = await takePicture(file);
          const image = data.data;

          const sendData = {
            photoUrl: image,
            type: chosen,
          };

          await creatingAiImg(sendData).then((response) => {
            setAiImg(response.data);
          });

          setUser((prev) => ({
            ...prev,
            profileUrl: image,
          }));

          toast.success(t("profile.change"));
          setRefresh((prev) => !prev);
          setLoadingImage(false);
        } catch (err) {
          setError(t("modal.err2"));
          setLoadingImage(false);
        }
      }
    }
  };

  const handleAvatarClick = (avatarSrc) => {
    setImage(avatarSrc);
  };

  const handleSelect = (item) => {
    setSelected((prevSelected) => {
      if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleRemove = (item) => {
    setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
  };

  const filteredItems = hobby
    ? hobby.filter((h) =>
        h.text.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const creatingHobby = async () => {
    try {
      setCreatingLoad(true);

      const selectedIds = selected.map((item) => item.id);
      const sendData = {
        employeeId: user.employeeId,
        hobby: selectedIds,
      };

      await createHobby(sendData);

      const updatedHobbies = selected.map((hobby) => ({
        id: hobby.id,
        text: hobby.text,
      }));

      setUser({
        ...user,
        hobbies: updatedHobbies,
      });

      if (user.birthDate === null) {
        setPage(2);
      } else if (user.profileUrl === null) {
        setPage(3);
      } else {
        setRefresh((prev) => !prev);
        setModal(false);
      }
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const createBirhtday = async () => {
    try {
      setCreatingLoad(true);

      const sendData = {
        birthDate: `${birthday} 00:00:00`,
      };

      await editUser(sendData);

      setUser({
        ...user,
        birthDate: sendData.birthDate,
      });

      if (user.profileUrl === null) {
        setPage(3);
      } else {
        setRefresh((prev) => !prev);
        setModal(false);
      }
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const createImage = async () => {
    try {
      setCreatingLoad(true);

      const sendData = {
        photoUrl: image,
      };

      await editUser(sendData);

      setUser({
        ...user,
        profileUrl: image,
      });
      setRefresh((prev) => !prev);

      setModal(false);
    } catch (err) {
      console.error("Error while creating hobby:", err);
    } finally {
      setCreatingLoad(false);
    }
  };

  const downloadImg = async () => {
    try {
      const response = await fetch(aiImg);
      const imageBlob = await response.blob();

      const imageUrl = URL.createObjectURL(imageBlob);

      const link = document.createElement("a");
      link.href = imageUrl;

      link.download = "profile-image.png";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

      URL.revokeObjectURL(imageUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const avatarImages = Array.from(
    { length: 40 },
    (_, i) => `/img/apu/Avatars-${String(i + 1).padStart(2, "0")}.png`
  ).filter((_, index) => index !== 25);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-xl  w-[455px] md:block sm:hidden hidden">
        {page === 1 ? (
          <div className="p-6">
            <p className="text-[20px] text-[#1E293B]">{t("modal.title")}</p>
            <p className="text-sm text-[#1E293B] mb-4">{t("modal.desc")}</p>
            <div className="relative mb-2">
              <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
              <input
                className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                placeholder={t("modal.placeholder")}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {hobby === null && user ? (
              <div className="flex justify-center items-center my-4">
                <p className="text-sm text-[#324D72]">{t("modal.loading")}</p>
              </div>
            ) : (
              <>
                {selected.length > 0 && (
                  <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll my-2">
                    {selected.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => handleRemove(item)}
                        className="bg-[#3D608C] px-2 py-1 rounded-lg flex items-center gap-2"
                      >
                        <p className="text-xs text-white">{item.text}</p>
                        <IoMdClose className="text-white" />
                      </button>
                    ))}
                  </div>
                )}
                <div className="flex flex-wrap items-center gap-2 max-h-[400px] overflow-y-scroll">
                  {filteredItems.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => handleSelect(item)}
                      className={`px-2 py-1 rounded-lg text-xs ${
                        selected.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "bg-[#3D608C] text-[#fff]"
                          : "bg-[#F4F6FB] text-[#3D608C]"
                      }`}
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              </>
            )}
            <div className="mt-6 flex items-center justify-between">
              <button
                onClick={() => {
                  setPage(2);
                }}
                className="text-[#1E293B] text-xs"
              >
                {t("modal.skip")}
              </button>
              <div className="flex items-center gap-2 ms-4">
                <GoDotFill className="text-[#1E293B]" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
              </div>
              <button
                onClick={creatingHobby}
                disabled={selected.length === 0 || creatingLoad}
                className={`flex items-center gap-2 ${
                  selected.length !== 0 ? "text-[#1E293B]" : "text-[#ABADB5]"
                } text-sm`}
              >
                {creatingLoad ? (
                  <span className="loader"></span>
                ) : (
                  t("modal.con")
                )}
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : page === 2 ? (
          <div>
            <div className="pt-6 px-6">
              <p className="text-[#1E293B] text-[20px] ">{t("modal.desc2")}</p>
              <p className="text-[#1E293B] text-sm mb-6 ">{t("modal.desc3")}</p>
              <div>
                <p className="text-[#293951] text-xs">
                  {t("modal.placeholder2")}
                </p>
                <div className="relative">
                  <MdOutlineCake className="text-[#293951] opacity-70 absolute top-2.5 left-2" />
                  <input
                    value={birthday}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                    className="w-full text-xs py-2 pe-4 ps-7 border border-[#CECFD3] rounded-xl"
                    type="date"
                  />
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-end">
              <img src="/img/modal/cake.svg" alt="cake" />
            </div>
            <div className="mt-6 flex items-center justify-between px-6 pb-6">
              <button
                onClick={() => {
                  setPage(3);
                }}
                className="text-[#1E293B] text-xs"
              >
                {t("modal.skip")}
              </button>
              <div className="flex items-center gap-2 ms-6">
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B]" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
              </div>
              <button
                onClick={createBirhtday}
                disabled={birthday === null}
                className={`flex items-center gap-2  ${
                  birthday !== null ? "text-[#1E293B]" : "text-[#ABADB5]"
                } text-sm`}
              >
                {t("modal.con")} <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : page === 3 ? (
          <div className="p-6">
            <p className="text-[#1E293B] text-[20px]">{t("modal.title3")}</p>
            <div className="mt-6 flex items-center justify-center">
              <div className="relative w-[200px] h-[200px] ">
                <label htmlFor="imageUpload" className="cursor-pointer">
                  <img
                    src={image}
                    alt="User"
                    className="w-full h-full object-cover rounded-full"
                  />
                  <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                    <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                  </div>
                </label>
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageCapture}
                />
              </div>
            </div>
            {(user.companyId === 197 || user.companyId === 4) && (
              <div
                onClick={() => {
                  setPage(4);
                }}
                className="mt-6 flex items-center justify-end"
              >
                <button className="px-4 py-2 bg-[#324d72] rounded-lg text-sm text-white hover:opacity-50">
                  AI ашиглаж зураг гаргах
                </button>
              </div>
            )}
            <div className="flex items-center justify-between gap-2 my-4">
              <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
              <p className="text-[#666666] text-sm">{t("modal.or")}</p>
              <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
            </div>
            <div>
              <p className="text-[#1E293B] font-semibold mb-4">
                {t("modal.desc3")}
              </p>
              {user.companyId === 197 || user.companyId === 4 ? (
                <div className="w-full flex items-center gap-6 overflow-x-scroll">
                  {avatarImages.map((src, index) => (
                    <div
                      key={index}
                      onClick={() => handleAvatarClick(src)}
                      className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                    >
                      <img
                        className="w-full h-full rounded-full"
                        src={src}
                        alt={`Avatar ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-between gap-4">
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user.svg")}
                  >
                    <img
                      src="/user.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user1.svg")}
                  >
                    <img
                      src="/user1.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user2.svg")}
                  >
                    <img
                      src="/user2.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                  <div
                    className="border border-[#EFEFEF] rounded-full cursor-pointer"
                    onClick={() => handleAvatarClick("/user3.svg")}
                  >
                    <img
                      src="/user3.svg"
                      alt="user"
                      className="w-[64px] h-[64px]"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-14 flex items-center justify-between px-6 ">
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="text-[#1E293B] text-xs"
              >
                {t("modal.skip")}
              </button>
              <div className="flex items-center gap-2 ms-6">
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B] text-sm opacity-60" />
                <GoDotFill className="text-[#1E293B]" />
              </div>
              <button
                onClick={createImage}
                disabled={
                  image === "/img/modal/user.svg" ||
                  image === null ||
                  image === user.profileUrl
                }
                className={`flex items-center gap-2  ${
                  image !== "/img/modal/user.svg"
                    ? "text-[#1E293B]"
                    : "text-[#ABADB5]"
                } text-sm`}
              >
                {t("modal.save")} <IoIosArrowForward />
              </button>
            </div>
          </div>
        ) : page === 4 ? (
          <div className="p-6">
            <p className="text-[#222] mb-6">
              Дараах зургуудаас 1 төрлийн сонгоно уу
            </p>
            <div className="">
              <div className="flex justify-between items-center mb-6">
                <button onClick={prevSlide}>
                  <IoIosArrowBack size={24} />
                </button>
                <p className="text-[#222] text-sm">{currentSlide.name}</p>
                <button onClick={nextSlide}>
                  <IoIosArrowForward size={24} />
                </button>
              </div>

              <div className="flex items-center justify-center gap-4">
                {currentSlide.images.map((img, index) => {
                  const normalizedName = currentSlide.name
                    ?.trim()
                    .toLowerCase()
                    .normalize("NFC");

                  const chosenValue = (() => {
                    if (normalizedName === "оффисын ажилтан") {
                      return index === 1 ? 1 : 4;
                    } else if (normalizedName === "үйлдвэрийн ажилтан") {
                      return index === 1 ? 2 : 5;
                    } else if (normalizedName === "парк") {
                      return index === 1 ? 3 : 6;
                    }
                    return 0;
                  })();

                  return (
                    <div
                      onClick={() => setChosen(chosenValue)}
                      key={index}
                      className={`${
                        chosen === chosenValue ? "border border-[#000]" : ""
                      } rounded-xl`}
                    >
                      <img
                        className="w-[150px] h-auto"
                        src={img}
                        alt={`carousel-${index}`}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-end mt-6">
                {chosen !== null ? (
                  <button
                    onClick={() => {
                      setPage(5);
                    }}
                    className={`flex items-center gap-2  ${
                      chosen !== null ? "text-[#1E293B]" : "text-[#ABADB5]"
                    } text-sm`}
                  >
                    {t("modal.con")} <IoIosArrowForward />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : page === 5 ? (
          <div className="p-6 relative">
            <p className="text-[#1E293B] text-[20px]">{t("modal.title3")}</p>
            <div className="mt-6 flex items-center justify-center">
              <div className="relative w-[200px] h-[200px]">
                <label htmlFor="imageUpload" className="cursor-pointer">
                  {aiImg ? (
                    <img
                      src={aiImg}
                      alt="User"
                      className="w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-full">
                      <MdPerson className="text-[100px] text-gray-400" />
                    </div>
                  )}

                  <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                    <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                  </div>

                  {loadingImage && (
                    <div className="absolute inset-0 bg-white bg-opacity-60 rounded-full flex items-center justify-center z-10">
                      <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
                  )}
                </label>

                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleAiImageCapture}
                />
              </div>
            </div>
            {aiImg !== null && (
              <div className="mt-8 justify-end flex gap-5">
                <button
                  className="px-4 py-2 text-sm bg-[#324d72] text-[#fff] rounded-lg"
                  onClick={downloadImg}
                >
                  {t("profile.save")}
                </button>
                <button
                  onClick={() => {
                    setModal(false);
                  }}
                  className="text-[#222] text-lg flex items-center gap-2"
                >
                  {t("profile.exit")}
                  <IoExitOutline />
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="md:hidden block sm:block bg-[#fff] min-h-screen scroll-none w-full relative">
        {page < 4 && (
          <div className="flex items-center justify-between mb-5 mt-5 mx-7">
            <div />

            <div className="flex items-center gap-2 ms-4">
              <GoDotFill
                className={`text-[#1E293B] ${page === 1 ? "" : "opacity-60"}`}
              />
              <GoDotFill
                className={`text-[#1E293B] ${page === 2 ? "" : "opacity-60"}`}
              />
              <GoDotFill
                className={`text-[#1E293B] ${page === 3 ? "" : "opacity-60"}`}
              />
            </div>
            <button
              onClick={() => {
                if (page === 1) {
                  setPage(2);
                } else if (page === 2) {
                  setPage(3);
                } else {
                  setModal(false);
                }
              }}
              className="text-[#575763] text-sm"
            >
              {t("modal.skip")}
            </button>
          </div>
        )}
        <div className="z-20">
          {page === 1 ? (
            <div className="mx-7">
              <div>
                <p className="text-[#1E293B] text-[24px] mb-2 font-medium">
                  {t("modal.title")}
                </p>
                <p className="text-[#1E293B]">{t("modal.desc")}</p>
              </div>
              <div className="relative mt-4 mb-3">
                <IoIosSearch className="absolute right-4 top-2 text-[#324D72]" />
                <input
                  className="text-[#324D72] text-xs px-4 py-2 border border-[#CEDAE9] rounded-xl w-full"
                  placeholder={t("modal.placeholder")}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="flex flex-wrap items-center gap-2 max-h-[45vh]  overflow-y-scroll">
                {filteredItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelect(item)}
                    className={`py-2 rounded-lg w-[48%] mb-2 ${
                      selected.some(
                        (selectedItem) => selectedItem.id === item.id
                      )
                        ? "bg-[#3D608C] text-[#fff]"
                        : "bg-[#F4F6FB] text-[#3D608C]"
                    }`}
                  >
                    {item.text}
                  </button>
                ))}
              </div>
            </div>
          ) : page === 2 ? (
            <div className=" mx-7">
              <div className="mb-5">
                <p className="text-medium text-[24px] text-[#1E293B]">
                  {t("modal.title2")}
                </p>
                <p className="text-[#1E293B]">{t("modal.desc2")}</p>
              </div>
              <div>
                <p className="text-[#293951] text-xs">
                  {t("modal.placeholder2")}
                </p>
                <div className="relative">
                  <MdOutlineCake className="text-[#293951] opacity-70 absolute top-2.5 left-2" />
                  <input
                    value={birthday}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                    className="w-full text-xs py-2 pe-4 ps-7 border border-[#CECFD3] rounded-xl"
                    type="date"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-10">
                <img src="/img/modal/birth.svg" alt="birthday" />
              </div>
            </div>
          ) : page === 3 ? (
            <div className="mx-7">
              <div className="mb-5">
                <p className="text-medium text-[24px] text-[#1E293B]">
                  {t("modal.title3")}
                </p>
              </div>
              <div className="mt-6 flex items-center justify-center">
                <div className="relative w-[200px] h-[200px]">
                  <label htmlFor="imageUpload" className="cursor-pointer">
                    <img
                      src={image}
                      alt="User"
                      className={`w-full h-full object-cover rounded-full ${
                        loadingImage ? "opacity-50" : ""
                      }`}
                    />
                    <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                      <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                    </div>
                  </label>
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageCapture}
                  />
                </div>

                {loadingImage && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000080] ">
                    <div className="animate-spin border-4 border-t-4 border-t-[#324D72] border-[#E9EDF5] w-[40px] h-[40px] rounded-full"></div>
                  </div>
                )}

                {error && (
                  <div className="mt-2 text-sm text-red-500">{error}</div>
                )}
              </div>

              {(user.companyId === 197 || user.companyId === 4) && (
                <div
                  onClick={() => {
                    setPage(4);
                  }}
                  className="mt-6 flex items-center justify-end"
                >
                  <button className="px-4 py-2 bg-[#324d72] rounded-lg text-sm text-white hover:opacity-50">
                    AI ашиглаж зураг гаргах
                  </button>
                </div>
              )}

              <div className="flex items-center justify-between gap-2 my-4">
                <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
                <p className="text-[#666666] text-sm">{t("modal.or")}</p>
                <div className="bg-[#666666] w-[45%] h-[2px] opacity-25" />
              </div>
              <div>
                <p className="text-[#1E293B] font-semibold mb-4">
                  {t("modal.desc3")}
                </p>
                {user.companyId === 197 || user.companyId === 4 ? (
                  <div className="w-full flex items-center gap-6 overflow-x-scroll">
                    {avatarImages.map((src, index) => (
                      <div
                        key={index}
                        onClick={() => handleAvatarClick(src)}
                        className="border border-[#EFEFEF] rounded-full cursor-pointer w-[64px] h-[64px] flex-shrink-0"
                      >
                        <img
                          className="w-full h-full rounded-full"
                          src={src}
                          alt={`Avatar ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-between gap-4">
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user.svg")}
                    >
                      <img
                        src="/user.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user1.svg")}
                    >
                      <img
                        src="/user1.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user2.svg")}
                    >
                      <img
                        src="/user2.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                    <div
                      className="border border-[#EFEFEF] rounded-full cursor-pointer"
                      onClick={() => handleAvatarClick("/user3.svg")}
                    >
                      <img
                        src="/user3.svg"
                        alt="user"
                        className="w-[64px] h-[64px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : page === 4 ? (
            <div className="p-6 mt-20">
              <p className="text-[#222] mb-6">
                Дараах зургуудаас 1 төрлийн сонгоно уу
              </p>
              <div className="">
                <div className="flex justify-between items-center mb-6">
                  <button onClick={prevSlide}>
                    <IoIosArrowBack size={24} />
                  </button>
                  <p className="text-[#222] text-sm">{currentSlide.name}</p>
                  <button onClick={nextSlide}>
                    <IoIosArrowForward size={24} />
                  </button>
                </div>

                <div className="flex items-center justify-center gap-4">
                  {currentSlide.images.map((img, index) => {
                    const normalizedName = currentSlide.name
                      ?.trim()
                      .toLowerCase()
                      .normalize("NFC");

                    const chosenValue = (() => {
                      if (normalizedName === "оффисын ажилтан") {
                        return index === 1 ? 1 : 4;
                      } else if (normalizedName === "үйлдвэрийн ажилтан") {
                        return index === 1 ? 2 : 5;
                      } else if (normalizedName === "парк") {
                        return index === 1 ? 3 : 6;
                      }
                      return 0;
                    })();

                    return (
                      <div
                        onClick={() => setChosen(chosenValue)}
                        key={index}
                        className={`${
                          chosen === chosenValue ? "border border-[#000]" : ""
                        } rounded-xl`}
                      >
                        <img
                          className="w-[150px] h-auto"
                          src={img}
                          alt={`carousel-${index}`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : page === 5 ? (
            <div className="p-6 mt-20 relative">
              <p className="text-[#1E293B] text-[20px]">{t("modal.title3")}</p>
              <div className="mt-6 flex items-center justify-center">
                <div className="relative w-[200px] h-[200px]">
                  <label htmlFor="imageUpload" className="cursor-pointer">
                    {aiImg ? (
                      <img
                        src={aiImg}
                        alt="User"
                        className="w-full h-full object-cover rounded-full"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-full">
                        <MdPerson className="text-[100px] text-gray-400" />
                      </div>
                    )}

                    <div className="absolute bottom-4 right-4 w-[36px] h-[36px] bg-[#FFFFFF] border border-[#E9EDF5] rounded-full flex items-center justify-center">
                      <MdOutlineCameraAlt className="text-xl text-[#324D72]" />
                    </div>

                    {loadingImage && (
                      <div className="absolute inset-0 bg-white bg-opacity-60 rounded-full flex items-center justify-center z-10">
                        <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                      </div>
                    )}
                  </label>

                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleAiImageCapture}
                  />
                </div>
              </div>
              {aiImg !== null && (
                <div className="fixed bottom-10 right-10 flex gap-5">
                  <button
                    className="px-4 py-2 text-sm bg-[#324d72] text-[#fff] rounded-lg"
                    onClick={downloadImg}
                  >
                    {t("profile.save")}
                  </button>
                  <button
                    onClick={() => {
                      setModal(false);
                    }}
                    className="text-[#222] text-lg flex items-center gap-2"
                  >
                    {t("profile.exit")}
                    <IoExitOutline />
                  </button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {page < 5 && (
          <div className="bg-[#F4F6FB] w-full flex justify-end pe-4 pt-2 pb-3 fixed bottom-0">
            <button
              onClick={() => {
                if (page === 1) {
                  creatingHobby();
                }
                if (page === 2) {
                  createBirhtday();
                }
                if (page === 3) {
                  createImage();
                }
                if (page === 4) {
                  setPage(5);
                }
              }}
              disabled={
                page === 1
                  ? selected.length === 0 || creatingLoad
                  : page === 2
                  ? birthday === null
                  : page === 3
                  ? image === "/img/modal/user.svg" ||
                    image === null ||
                    image === user.profileUrl
                  : chosen === null
              }
              className={`flex items-center gap-1 text-[#fff] bg-[#324d72] px-3 py-2 text-sm rounded-lg`}
            >
              {creatingLoad ? <span className="loader"></span> : t("modal.con")}
              <IoIosArrowForward className="text-xs" />
            </button>
          </div>
        )}
      </div>
      {splash && (
        <div className="absolute w-full h-full bg-[#F4F6FB] md:hidden block sm:block"></div>
      )}
      {!animationEnd && (
        <>
          <div className="absolute left-50% right-50% translate-y-1/2 text-ani">
            <p className="text-[#fff] text-[22px]">
              <span className="shaking">👋</span> {t("modal.hello")}
            </p>
          </div>
          <div className="absolute start ">
            <img src="/img/modal/animation.png" alt="png" />
          </div>
        </>
      )}
    </div>
  );
};

export default Modal;
